import React, { useState, useCallback } from 'react';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone'
import { useAppDispatch } from '../../../../app/hooks';
import { tryToImportAssetData } from '../../../../store/brokers/admin/assets/assetsSlice';
import ImportAssetsSuccess from '../details/ImportAssetsSuccess';
import ImportAssetsCsv from '../details/ImportAssetsCsv';
import Modal from '../../../../shared/modal';

interface IImportAssetsModal {
    openImportModal: boolean;
    assetTemplateId: string;
    handleCloseModal: () => void;
    onImportAssetsSuccessfully: () => void;
}

const ImportAssetsModal = ({
    openImportModal,
    assetTemplateId,
    handleCloseModal,
    onImportAssetsSuccessfully
}: IImportAssetsModal) => {
    const dispatch = useAppDispatch();
    const [importError, setImportError] = useState<string>()
    const [importedSuccessfully, setImportedSuccessfully] = useState<boolean>(false)
    const [fileSelected, setFileSelected] = useState<any>()

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const onCloseModal = () => {
        handleCloseModal();
        setImportedSuccessfully(false);
        setImportError(undefined);
        setFileSelected(undefined)
    }

    const onUploadFile = async () => {
        try {
            Papa.parse(fileSelected, {
                header: true,
                skipEmptyLines: true,
                complete: async function (results: any) {
                    const payload = {
                        assetTemplateId: assetTemplateId,
                        assets: results?.data || []
                    }
                    await dispatch(tryToImportAssetData(payload)).unwrap()
                }
            })
            setImportError(undefined)
            setImportedSuccessfully(true)
            onImportAssetsSuccessfully()
        } catch (err) {
            setImportedSuccessfully(false)
            setImportError(`${err}`)
        }
    }

    return (
        <Modal
            open={openImportModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                {importedSuccessfully ?
                    <ImportAssetsSuccess
                        handleCloseModal={onCloseModal}
                    />
                    : <ImportAssetsCsv
                        onUploadFile={onUploadFile}
                        getInputProps={getInputProps}
                        getRootProps={getRootProps}
                        isDragActive={isDragActive}
                        fileSelected={fileSelected}
                        importError={importError}
                    />
                }
            </div>
        </Modal>
    )
}

export default ImportAssetsModal;