import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IToast } from '../../../../interfaces/components/IToast';
import { IAssetFields } from '../../../../interfaces/asset-templates/IAssetTemplate';
import {
    tryToDisableAssetInventoryField,
    tryToFetchInventoryFilters,
} from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import FieldsModal from '../modals/FieldsModal';
import AssetFiltersModal from '../modals/AssetFiltersModal';
import AssetTemplateFieldsTable from '../tables/AssetTemplateFieldsTable';
import Toast from '../../../../shared/toast';
import Button from '../../../../shared/button';
import DeleteModal from '../../../../shared/delete-modal';
import EmptyContainer from '../../../../shared/empty-container';


const InventoryFields = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<IAssetFields[]>([]);
    const [showToast, setShowToast] = useState<IToast | null>()
    const [fieldItemId, setFieldItemId] = useState<string | null>()
    const [assetFilters, setAssetFilters] = useState<Array<any>>()
    const [showFieldModal, setShowFieldModal] = useState<boolean>(false)
    const [showDeleteFieldModal, setShowDeleteFieldModal] = useState<boolean>(false)
    const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false)
    const [pageAccess, setPageAccess] = useState<any>()
    const rowsLoading = useAppSelector((state) => state.assetsTemplates?.assetTemplateFieldsAreLoading);
    const stateFilters = useAppSelector((state) => state.assetsTemplates?.inventoryFieldFilters);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const state = useAppSelector((state) => state.assetsTemplates);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Asset Catalogs')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Asset Catalog Details')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.assetTemplateDetails) {
            const rows =
                state.assetTemplateDetails && state.assetTemplateDetails.inventoryFields && Array.isArray(state.assetTemplateDetails?.inventoryFields)
                    ? state.assetTemplateDetails?.inventoryFields?.filter((r) => r?.enabled).map((r) => ({ ...r, id: r.id }))
                    : [];
            setRows(rows);
        }
    }, [state.assetTemplateDetails]);

    useEffect(() => {
        if (stateFilters) {
            setAssetFilters(stateFilters)
        }
    }, [stateFilters])

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchInventoryFilters(id))
        }
    }, []);

    const onCloseFieldModal = () => {
        setFieldItemId(null)
        setShowFieldModal(false)
    }

    const onAddNewAssetTemplateField = (id?: string) => {
        if (id) {
            setFieldItemId(id)
        }
        setShowFieldModal(true)
    }

    const onDisableAssetField = async () => {
        try {
            if (fieldItemId) {
                const data = {
                    assetId: id,
                    fieldId: fieldItemId
                }
                await dispatch(tryToDisableAssetInventoryField(data)).unwrap();
                setShowToast({ type: 'error', message: 'Field deleted successfully' });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setFieldItemId(null)
        setShowDeleteFieldModal(false)
    }

    const onDeleteAssetField = (id: string) => {
        setFieldItemId(id)
        setShowDeleteFieldModal(true)
    }

    const onSaveFieldChanges = async (type: string, message: string) => {
        setShowToast({ type, message });
    }

    const onCloseDeleteFieldModal = () => {
        setFieldItemId(null)
        setShowDeleteFieldModal(false)
    }

    const onToggleFilterModal = () => {
        setShowFiltersModal(!showFiltersModal)
    }

    return (
        <div>
            <div className='flex flex-row justify-end'>
                <Button
                    label={'Setup Filters'}
                    dataQa={'setup-filters'}
                    className={'!shadow-none btn-main mr-2'}
                    onClickButton={onToggleFilterModal}
                />
                <Button
                    label={'Add New Inventory Fields'}
                    dataQa={'add-new-inventory-fields'}
                    className={'btn-main'}
                    onClickButton={onAddNewAssetTemplateField}
                />
            </div>
            <div className='my-5 relative'>
                {(rows?.length > 0 || rowsLoading) ?
                    <AssetTemplateFieldsTable
                        rows={rows}
                        type={'inventory'}
                        assetId={id || ''}
                        rowsLoading={rowsLoading}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        onDeleteAssetField={onDeleteAssetField}
                        onEditAssetField={onAddNewAssetTemplateField}
                    />
                    : <EmptyContainer text={'No field added yet'} showImage={true} />
                }
            </div>
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            <FieldsModal
                openFieldsModal={showFieldModal}
                handleCloseFieldsModal={onCloseFieldModal}
                onSaveChanges={onSaveFieldChanges}
                assetTemplateId={id || ''}
                fieldItemId={fieldItemId}
                type={'inventory'}
            />
            <DeleteModal
                openDeleteModal={showDeleteFieldModal}
                handleCloseDeleteModal={onCloseDeleteFieldModal}
                onApproveDeletion={onDisableAssetField}
            />
            <AssetFiltersModal
                openFilterModal={showFiltersModal}
                handleCloseModal={onToggleFilterModal}
                items={assetFilters || []}
                fields={rows || []}
                type={'inventory'}
                assetTemplateId={id || ''}
            />
        </div >
    )
}

export default InventoryFields;