import { AxiosResponse } from 'axios';
import { ASSETS_API_URL } from '../../../../config';
import assetsAxios from '../../../../utils/axios/assets.axios';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IAssetStatusUpdate } from '../../../../interfaces/assets/IAssetStatusUpdate';
import { IAssets } from '../../../../interfaces/assets/IAssets';

const tryToFetchAssets = (
    pageNumber: number,
    pageSize: number,
    id: string,
    filters?: string,
    data?: any,
): Promise<AxiosResponse<any>> => {
    const url = filters
        ? `${ASSETS_API_URL}/assets/${id}?page=${pageNumber}&size=${pageSize}&search=${filters}`
        : `${ASSETS_API_URL}/assets/${id}?page=${pageNumber}&size=${pageSize}`;
    return assetsAxios.put<any>(url, data);
};

const tryToFetchAssetCurrencies = (): Promise<AxiosResponse<any>> => {
    const url = `${ASSETS_API_URL}/assets`;
    return assetsAxios.get<any>(url);
};

const tryToDeleteAssetCatalogData = (assetCatalogId: string): Promise<AxiosResponse<any>> => {
    const url = `${ASSETS_API_URL}/assets/batch/${assetCatalogId}`;
    return assetsAxios.delete<any>(url);
};

const tryToFetchSingleAsset = (
    id: string,
    assetId: string,
): Promise<AxiosResponse<ApiResponse<any>>> => {
    return assetsAxios.get(`${ASSETS_API_URL}/assets/${id}/${assetId}`);
};

const tryFetchAssetFieldValues = (assetTemplateId: string, fieldName: string, search?: string, replacements?: boolean): Promise<AxiosResponse> => {
    let url = search ? `${ASSETS_API_URL}/assets/${assetTemplateId}/field/${fieldName}/${search}` : `${ASSETS_API_URL}/assets/${assetTemplateId}/field/${fieldName}`
    if (replacements) {
        url = url + '?replacements=true'
    }
    return assetsAxios.get(url)
}

const tryFetchAssetFieldValuesDependent = (assetTemplateId: string, data?: any, replacements?: boolean): Promise<AxiosResponse> => {
    let url = `${ASSETS_API_URL}/assets/${assetTemplateId}/values/filter`
    if (replacements) {
        url = url + '?replacements=true'
    }
    return assetsAxios.put(url, data)
}

const tryToFetchAssetValues = (assetTemplateId: string, data?: any): Promise<AxiosResponse> => {
    return assetsAxios.put(`${ASSETS_API_URL}/assets/${assetTemplateId}/values`, data)
}

const tryToAddAsset = (data: IAssets): Promise<AxiosResponse> => {
    return assetsAxios.post(`${ASSETS_API_URL}/assets`, data);
};

const tryToEditAsset = (id: string, payload: IAssets): Promise<AxiosResponse> => {
    return assetsAxios.put(`${ASSETS_API_URL}/assets/edit/${id}`, payload);
};

const tryToChangeAssetsStatus = (assetTempalteId: string, payload: IAssetStatusUpdate): Promise<AxiosResponse> => {
    return assetsAxios.put(`${ASSETS_API_URL}/assets/${assetTempalteId}/statuses`, payload);
};

const tryToDisableAsset = (assetTemplateId: string, assetId: string): Promise<AxiosResponse> => {
    return assetsAxios.delete(`${ASSETS_API_URL}/assets/${assetTemplateId}/${assetId}`);
};

const tryToImportAssetData = (data: {
    assetTemplateId: string;
    assets: Array<any>;
}): Promise<AxiosResponse> => {
    return assetsAxios.post(`${ASSETS_API_URL}/brokers/assets/import`, data);
};

const tryToImportAssetImagesData = (assetTemplateId: string, data: any, rental?: boolean): Promise<AxiosResponse> => {
    return assetsAxios.put(`${ASSETS_API_URL}/assets/${assetTemplateId}/images`, { data: data, rental });
};

const tryToFetchExtendedAssets = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
): Promise<AxiosResponse<any>> => {
    const url = filters
        ? `${ASSETS_API_URL}/extended-assets/all?page=${pageNumber}&size=${pageSize}&search=${filters}`
        : `${ASSETS_API_URL}/extended-assets/all?page=${pageNumber}&size=${pageSize}`;
    return assetsAxios.put<any>(url);
};

const tryToAddExtendedAsset = (data: any): Promise<AxiosResponse> => {
    return assetsAxios.post(`${ASSETS_API_URL}/extended-assets`, data);
};

const tryToEditExtendedAsset = (id: string, data: any): Promise<AxiosResponse> => {
    return assetsAxios.put(`${ASSETS_API_URL}/extended-assets/${id}`, data);
};

const tryToFetchSingleAssetExtended = (id: string,): Promise<AxiosResponse<ApiResponse<any>>> => {
    return assetsAxios.get(`${ASSETS_API_URL}/extended-assets/${id}`);
};

const tryToImportExtendedAssetData = (data: {
    extendedAssets: Array<any>;
}): Promise<AxiosResponse> => {
    return assetsAxios.post(`${ASSETS_API_URL}/extended-assets/import`, data);
};

const tryToDownloadExtendedAssetTemplate = (): Promise<AxiosResponse> => {
    return assetsAxios.get(`${ASSETS_API_URL}/extended-assets/download`);
};

const tryToDownloadExtendedAssetData = (): Promise<AxiosResponse> => {
    return assetsAxios.get(`${ASSETS_API_URL}/extended-assets/download/all`);
};

const tryToDeleteExtendedAsset = (id: string): Promise<AxiosResponse> => {
    return assetsAxios.delete(`${ASSETS_API_URL}/extended-assets/${id}`);
};

const tryToSearchNotAddedExtendedAssets = (search?: any): Promise<AxiosResponse> => {
    const url = search ? `${ASSETS_API_URL}/extended-assets/search/${search}` : `${ASSETS_API_URL}/extended-assets/search`
    return assetsAxios.get(url);
};

export const AssetsService = {
    tryToFetchAssetCurrencies,
    tryToFetchAssets,
    tryToFetchSingleAsset,
    tryToFetchAssetValues,
    tryFetchAssetFieldValues,
    tryToEditAsset,
    tryToChangeAssetsStatus,
    tryToAddAsset,
    tryToDisableAsset,
    tryToImportAssetData,
    tryToImportAssetImagesData,
    tryToFetchExtendedAssets,
    tryToAddExtendedAsset,
    tryToEditExtendedAsset,
    tryToFetchSingleAssetExtended,
    tryFetchAssetFieldValuesDependent,
    tryToImportExtendedAssetData,
    tryToDownloadExtendedAssetData,
    tryToDownloadExtendedAssetTemplate,
    tryToDeleteAssetCatalogData,
    tryToDeleteExtendedAsset,
    tryToSearchNotAddedExtendedAssets
};
