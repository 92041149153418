import React, { useRef, useState } from 'react';
import AiAgentsNewMessage from './AiAgentsNewMessage';
import AiAgentsContent from './AiAgentsContent';
import { useParams } from 'react-router-dom';

const AiAgents = ({
	type
}: {
	type?: 'seller' | 'buyer'
}) => {
	const [showUpload, setShowUpload] = useState<boolean>(false)
	const childRef = useRef<any>(null);
	const { id } = useParams();

	const handleDrop = (event: any) => {
		event.preventDefault();
		const files = Array.from(event.dataTransfer.files);
		if (childRef.current && files?.[0]) {
			childRef.current.handleDropFileChild(files[0]);
			setShowUpload(false);
		}
	};
	
	const handleDragOver = (event: any) => {
		event.preventDefault();
		!showUpload && setShowUpload(true);
	};

	const handleDragLeave = () => {
		showUpload && setShowUpload(false);
	};

	return (
		<div className='w-full ai-bg bg-no-repeat bg-center relative h-full'>
			<div
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				onDrop={handleDrop}
				className={'flex flex-col h-[calc(100vh-250px)] ' + (showUpload ? 'pointer border-2 border-dashed bg-black bg-opacity-10' : '')}
			>
				<AiAgentsContent type={type} />
				<input type='file' className='hidden' onChange={childRef.current?.handleFileChangeChild} ref={childRef.current?.fileUploadRef} id='file-upload-ai' />
			</div>
			<AiAgentsNewMessage chatId={id || ''} ref={childRef} type={type} />
		</div>

	)
}

export default AiAgents;
