import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom';

import Button from '../../../../shared/button';
import EmptyContainer from '../../../../shared/empty-container';
import { IToast } from '../../../../interfaces/components/IToast';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import SalesGradeMappingsTable from '../tables/SalesGradeMappingsTable';
import { ISalesGradeMapping } from '../../../../interfaces/asset-templates/ISalesGradeMapping';
import { tryToFetchAssetTemplateMappings } from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import EditSalesGradeMappingsModal from '../modals/EditSalesGradeMappingsModal';
import Toast from '../../../../shared/toast';

const SalesGradeMappings = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<ISalesGradeMapping[]>([]);
    const [showEditSalesGradeMappingsModal, setShowEditSalesGradeMappingsModal] = useState(false)
    const [showToast, setShowToast] = useState<IToast | null>()
    const [pageAccess, setPageAccess] = useState<any>()
    const rowsLoading = useAppSelector((state) => state.assetsTemplates?.assetTemplateSalesGradeIsLoading);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const state = useAppSelector((state) => state.assetsTemplates);

    const getSalesGradeData = async () => {
        if (id) {
            await dispatch(tryToFetchAssetTemplateMappings({ assetTemplateId: id })).unwrap()
        }
    }

    const onCloseEditSalesGradeMappings = () => {
        setShowEditSalesGradeMappingsModal(false)
    }

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Asset Catalogs')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Asset Catalog Details')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        getSalesGradeData()
    }, [id]);

    useEffect(() => {
        if (state.assetTemplateSalesGradeMappings) {
            setRows(state.assetTemplateSalesGradeMappings)
        } else {
            setRows([])
        }
    }, [state.assetTemplateSalesGradeMappings])

    return (
        <div>
            {!rowsLoading && <div className='flex flex-row justify-end'>
                <Button
                    label={'Edit Sales Grade'}
                    dataQa={'edit-sales-grade'}
                    className={`${(!accessControl || pageAccess?.actions?.['Edit Sales Grade']) ? 'btn-main' : 'btn-main-disable'}`}
                    onClickButton={() => { (!accessControl || pageAccess?.actions?.['Edit Sales Grade']) && setShowEditSalesGradeMappingsModal(true) }}
                />
            </div>}
            <div className='my-5 relative'>
                {(rows?.length > 0 || rowsLoading) ?
                    <SalesGradeMappingsTable
                        rows={rows}
                        assetId={id || ''}
                        rowsLoading={rowsLoading}
                    />
                    : <EmptyContainer text={'No sales grade added yet'} showImage={true} />
                }
            </div>
            <EditSalesGradeMappingsModal open={showEditSalesGradeMappingsModal} onClose={onCloseEditSalesGradeMappings} rows={rows} assetTemplateId={id || ''} setShowToast={setShowToast} />
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div>
    )
}

export default SalesGradeMappings