import React, { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToChangeAssetsStatus } from '../../../../store/brokers/admin/assets/assetsSlice';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface IChangeItemStatusModal {
    openModal: boolean;
    assetTemplateId: string;
    assetId: string;
    actualStatus: string;
    handleCloseModal: () => void;
    onSaveSuccessfully: (message: string) => void;
}

const ChangeItemStatusModal = ({
    openModal,
    assetTemplateId,
    assetId,
    actualStatus,
    handleCloseModal,
    onSaveSuccessfully
}: IChangeItemStatusModal) => {
    const dispatch = useAppDispatch();
    const [saveError, setSaveError] = useState<string>()


    const onCloseModal = () => {
        handleCloseModal();
        setSaveError(undefined)
    }

    const onSubmit = async (status: any) => {
        try {
            const formData = {
                assetTemplateId: assetTemplateId,
                data: {
                    assets: [assetId],
                    status: status
                }
            }
            await dispatch(tryToChangeAssetsStatus(formData)).unwrap()
            onSaveSuccessfully('Asset status changed successfully')
            onCloseModal()
        } catch (err: any) {
            setSaveError(`${err?.response?.data?.message}`)
        }
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            contentContainerStyle='min-w-[600px]'>
            <div className={'p-4 min-w-[550px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Asset Status</p>
                </div>
                {saveError && <Error text={saveError} />}
                <div className='my-5'>
                    <p>You can change Asset actual status by clicking the button below.</p>
                </div>
                <div className='flex flex-row justify-end'>
                    {(actualStatus === 'disabled' || actualStatus === 'new') &&
                        <Button
                            className='btn-main'
                            label={'Enable Asset'}
                            onClickButton={() => onSubmit('enabled')}
                        />
                    }
                    {(actualStatus === 'enabled' || actualStatus === 'new') &&
                        <Button
                            className='btn-error !rounded ml-3'
                            label={'Disable Asset'}
                            onClickButton={() => onSubmit('disabled')}
                        />
                    }
                </div>
            </div>
        </Modal>
    )
}

export default ChangeItemStatusModal;