import React from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '../../../../shared/button';

interface IImportCategoryFile {
    categoryId: string;
    onDrop: (file: any, categoryId: string) => void;
}

const ImportCategoryFile = ({
    categoryId,
    onDrop,
}: IImportCategoryFile) => {
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles: any) => onDrop(acceptedFiles, categoryId),
    });
    return (
        <>
            <div className='flex flex-row items-center mr-10' {...getRootProps()}>
                <input {...getInputProps()} />
                <Button
                    icon={<img src='/assets/shared/add-plus.svg' className='w-[30px] object-contains pr-2' />}
                    className={'btn-main min-w-[150px] !bg-white !py-2 !pr-3 !shadow-none !border-none flex flex-row items-center'}
                    label={'Upload CSV'}
                    dataQa={'select-file'}
                />
            </div>
        </>
    )
}
export default ImportCategoryFile