import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/hooks';
import intercomIntegration from '../../../../utils/intercom/intercom-integration';
import { ErrorPayload } from '../../../../interfaces/auth/IErrorPayload';
import { UserPassPayload } from '../../../../interfaces/auth/IUser';
import { useLoginMutation } from '../../../../store/auth/authApi';
import { useGetWingUserHashMutation } from '../../../../store/user/userApi';
import { tryToAuthorizeUser } from '../../../../store/brokers/admin/partners/partnersSlice';
import Loading from '../../../../shared/Loading';
import Button from '../../../../shared/button';
import Input from '../../../../shared/input';


interface ILoginForm {
    onShowHideForgotPassword: () => void;
}

const LoginForm = ({
    onShowHideForgotPassword
}: ILoginForm) => {
    const dispatch = useAppDispatch()
    const { register, handleSubmit, formState: { errors } } = useForm<UserPassPayload>();
    const [loginMutation, { isLoading, error }] = useLoginMutation();
    const [errorAuthorize, setErrorAuthorize] = useState<string>()
    const [getWingUserHash] = useGetWingUserHashMutation();

    const navigate = useNavigate();
    let errorMsg: any

    const onCallingIntercom = async () => {
        try {
            const response: any = await getWingUserHash();
            await intercomIntegration(response?.data?.userHashId, response?.data?.userId);
        } catch (err) {
            // error here
        }
    }

    const onSubmit = async (payload: UserPassPayload) => {
        try {
            const { data } = await loginMutation(payload).unwrap();
            const roles = data.servicePayload.accessToken.payload['cognito:groups'];
            localStorage.setItem('accessToken', data.token);
            localStorage.setItem('refreshToken', data.refreshToken);
            localStorage.setItem('cgToken', data?.servicePayload?.accessToken?.jwtToken || '')
            localStorage.setItem('roles', roles?.[0] || '');
            localStorage.removeItem('user')
            if (roles?.[0] !== 'admins') {
                const response = await dispatch(tryToAuthorizeUser({ dashboard: 'brokers' })).unwrap()
                if (!response) {
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('refreshToken')
                    localStorage.removeItem('roles')
                    localStorage.removeItem('user')
                    setErrorAuthorize('You are not authorized to access this dashboard')
                    return;
                }
            }
            if (roles?.[0] !== 'admins') {
                onCallingIntercom();
            } else {
                (window as any).Intercom('shutdown');
            }
            navigate(roles?.[0] === 'admins' ? '/users' : '/insights');
        } catch (err: any) {
            errorMsg = err?.data?.message
        }
    }

    const err = { ...error as ErrorPayload };
    errorMsg = err.status === 401 ? 'Username or password are incorrect' : 'Something went wrong'
    return (
        <>
            <form className='mt-8 mb-5' onSubmit={handleSubmit(onSubmit)}>
                {(!!err.data || errorAuthorize) && <div className='p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800' role='alert'>
                    <span className='font-medium'> {errorAuthorize || err.data.message || errorMsg} </span>
                </div>}
                <Input
                    placeholder='Username'
                    register={register('username', {
                        required: {
                            message: 'Username is required',
                            value: true,
                        },
                        validate: (value: string) => !!value.trim() || 'Username is required'
                    })}
                    dataQa={'username-login'}
                    error={errors.username?.message}
                />
                <Input
                    placeholder='Password'
                    type='password'
                    register={register('password', {
                        required: {
                            message: 'Password is required',
                            value: true,
                        },
                    })}
                    dataQa={'password-login'}
                    error={errors.password?.message}
                />
                <div className='my-5 flex flex-row justify-center items-center'>
                    <p className='text-[#8a8a8e]'>Did you forget your password?</p>
                    <Button
                        className='text-primary-default !pl-0 !ml-1 underline font-medium'
                        label='Reset Password'
                        dataQa={'reset-password'}
                        onClickButton={onShowHideForgotPassword}
                    />
                </div>
                <button data-qa={'submit-login'} className=' mt-1 w-full justify-center flex btn-primary !py-2 px-5 text-white rounded !font-semibold' type='submit'>
                    {isLoading && <Loading />}
                    Login
                </button>
            </form>
        </>
    )
}

export default LoginForm;