import React from 'react';

export const navigationAgents = [
    { name: 'Insights', href: '/insights', icon: <img alt='insights' src='/assets/navigation-icons/insights.svg' />, iconActive: <img alt='insights-active' src='/assets/navigation-icons/insights-black.svg' />, enabled: true },
    // { name: 'Bidding', href: '/bidding', icon: <img alt='bidding' src='/assets/navigation-icons/bidding.svg' />, enabled: true },
    { name: 'Orders', href: '/orders', icon: <img alt='orders' src='/assets/navigation-icons/orders.svg' />, iconActive: <img alt='orders-active' src='/assets/navigation-icons/orders-black.svg' />, enabled: true },
    { name: 'Order Items', href: '/order-items', icon: <img alt='order-items' src='/assets/navigation-icons/order-items.svg' />, iconActive: <img alt='order-items-active' src='/assets/navigation-icons/order-items-black.svg' />, enabled: true },
    { name: 'Multiple Cashouts', href: '/multiple-cashouts', icon: <img alt='multiple-cashouts' src='/assets/navigation-icons/multiple-cashout.svg' />, iconActive: <img alt='multiple-cashouts-active' src='/assets/navigation-icons/multiple-cashout-black.svg' />, enabled: true },
    { name: 'Wing Packages', href: '/wing-packages', icon: <img alt='wing-packages' src='/assets/navigation-icons/wing-packages.svg' />, iconActive: <img alt='wing-packages-active' src='/assets/navigation-icons/wing-packages-black.svg' />, enabled: true },
    { name: 'Pricing', href: '/pricing', icon: <img alt='pricing' src='/assets/navigation-icons/pricing.svg' />, iconActive: <img alt='pricing-active' src='/assets/navigation-icons/pricing-black.svg' />, enabled: true },
    { name: 'Pricing History', href: '/pricing-history', icon: <img alt='pricing-history' src='/assets/navigation-icons/pricing-history.svg' />, iconActive: <img alt='pricing-history-active' src='/assets/navigation-icons/pricing-history-black.svg' />, enabled: true },
    // { name: 'Wholesale Price', href: '/wholesale-price', icon: <img alt='wholesale-prices' src='/assets/navigation-icons/wholesale-price.svg' />, iconActive: <img alt='wholesale-price-active' src='/assets/navigation-icons/wholesale-price-black.svg' />, enabled: true },
    // { name: 'Wholesale Price History', href: '/wholesale-price-history', icon: <img alt='wholesale-price-history' src='/assets/navigation-icons/wholesale-history.svg' />, iconActive: <img alt='wholesale-history-active' src='/assets/navigation-icons/wholesale-history-black.svg' />, enabled: true },
    { name: 'Purchase Orders', href: '/purchase-orders', icon: <img alt='purchase-orders' src='/assets/navigation-icons/purchases.svg' />, iconActive: <img alt='purchase-orders-active' src='/assets/navigation-icons/purchases-active.svg' />, enabled: true },
    { name: 'Sales Orders', href: '/sales-orders-broker', icon: <img alt='sales-orders' src='/assets/navigation-icons/sales.svg' />, iconActive: <img alt='sales-orders-active' src='/assets/navigation-icons/sales-active.svg' />, enabled: true },
    { name: 'Invoices', href: '/invoices', icon: <img alt='invoices' src='/assets/navigation-icons/invoices.svg' />, iconActive: <img alt='invoices-active' src='/assets/navigation-icons/invoices-black.svg' />, enabled: true },
    { name: 'Category Mapping', href: '/category-mapping', icon: <img alt='mapping' src='/assets/navigation-icons/category-mapping.svg' />, iconActive: <img alt='category-mapping-active' src='/assets/navigation-icons/category-mapping-black.svg' />, enabled: true },
    { name: 'Customer Care', href: '/customer-care', icon: <img alt='customer care' src='/assets/navigation-icons/customer-care.svg' />, iconActive: <img alt='' src='/assets/navigation-icons/customer-care-black.svg' />, enabled: true },
    { name: 'Members', href: '/members', icon: <img alt='members' src='/assets/navigation-icons/agents.svg' />, iconActive: <img alt='members-active' src='/assets/navigation-icons/agents-black.svg' />, enabled: true },
    { name: 'Return Merchandise Authorization', href: '/rma', icon: <img alt='rma' src='/assets/navigation-icons/rma.svg' />, iconActive: <img alt='rma-active' src='/assets/navigation-icons/rma-black.svg' />, enabled: true },
    { name: 'User Access Control', href: '/user-access-control', icon: <img alt='user-access-control' src='/assets/navigation-icons/user-access-control.svg' />, iconActive: <img alt='user-access-control' src='/assets/navigation-icons/user-access-control-black.svg' />, enabled: true, access: 'partners' },
];

export const extraNavigation = [
    { name: 'Profile', dataQa: 'profile', href: '/profile', icon: <img alt='profile' src='/assets/navigation-icons/profile.svg' />, iconActive: <img alt='profile-active' src='/assets/navigation-icons/profile-black.svg' />, enabled: true },
    // { name: 'Support', dataQa: 'support', href: '/support', icon: <img alt='support' src='/assets/navigation-icons/support.svg' />, iconActive: <img alt='support-active' src='/assets/navigation-icons/support-black.svg' />, enabled: true },
];

export const AiMenu = [
	{
		name: 'Dashboard',
		icon: <img alt='dashboard' src='/assets/navigation-icons/ai-agents/dashboard.svg' />
	},
	{
		name: 'AI Agents',
		icon: <img alt='dashboard' src='/assets/navigation-icons/ai-agents/ai-agents.svg' />
	},
	{
		name: 'AI Grading',
		icon: <img alt='dashboard' src='/assets/navigation-icons/ai-agents/ai-grading.svg' />
	},
	{
		name: 'AI Pricing',
		icon: <img alt='dashboard' src='/assets/navigation-icons/ai-agents/ai-pricing.svg' />
	},
	{
		name: 'Guaranteed By Back',
		icon: <img alt='dashboard' src='/assets/navigation-icons/ai-agents/guaranteed-buy-back.svg' />
	},
	{
		name: 'Embeded Financing',
		icon: <img alt='dashboard' src='/assets/navigation-icons/ai-agents/embeded-financing.svg' />
	},
];