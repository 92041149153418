import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { AdminGuard } from './guards/RolesGuard';
import { UserGuard } from './guards/UserGuard';
import { routes } from './router/routes';
import RouteWrapper from './router/RouteWrapper';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { tryToFetchPartnerNavigation } from './store/brokers/admin/partners/partnersSlice';

const App = () => {
    // const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const redirectNotExistingRoutes = UserGuard() ? (AdminGuard() ? '/users' : '/insights') : '/apply'
    const [redirectPartner, setRedirectPartner] = useState<any>()
    const token = localStorage.getItem('accessToken')
    const navigation = useAppSelector(state => state?.partners?.partnerNavigation)

    const getNavigationData = async () => {
        try {
            await dispatch(tryToFetchPartnerNavigation()).unwrap()
        } catch (err) {
            // error data here
        }
    }

    useEffect(() => {
        if (!navigation && token) {
            getNavigationData()
        }
    }, [])

    // useEffect(() => {
    //     if (!token) {
    //         localStorage.removeItem('refreshToken')
    //         navigate('/apply');
    //     }
    // }, [token])

    const findChildIfExist = (parent: any, childPath: any) => {
        const findParent = (navigation && navigation?.length > 0) && navigation?.find((item: any) => item?.path === parent)
        const findChild = findParent && findParent?.children && findParent?.children?.length > 0 && findParent?.children?.find((item: any) => item?.path === childPath)
        return !navigation || !!findChild
    }

    const findParentIfExist = (parent: any) => {
        const findParent = (navigation && navigation?.length > 0) && navigation?.find((item: any) => item?.path === parent)
        return !navigation || !!findParent
    }

    useEffect(() => {
        if (navigation && token && (!redirectPartner || redirectPartner === '/apply')) {
            const findIfInsightsExists = (navigation && navigation?.length > 0) && navigation?.find((item: any) => item?.path === '/insights')
            if (!findIfInsightsExists) {
                const getFirstPage = (navigation && navigation?.length > 0) && navigation?.[0]
                setRedirectPartner(getFirstPage?.path)
            } else if (!AdminGuard()) {
                setRedirectPartner('/insights')
            }
        }
        else if (!token && !redirectPartner) {
            setRedirectPartner('/apply');
            (window as any).Intercom('shutdown');
        }
    }, [navigation, token])

    return (
        <div className='App !font-dm_sansregular'>
            <Routes>
                {routes?.filter((item: any) => token && item?.key !== 'guest' ? findParentIfExist(item?.path) : true)?.map((route: any, index: number) => {
                    const children = (route?.childrens && route?.childrens?.length > 0 && token && navigation) ? route?.childrens?.filter((item: any) => item?.path === '' || findChildIfExist(route?.path, item?.path)) : route?.childrens
                    return (
                        children ? (
                            <React.Fragment key={index}>
                                {route.index && <Route
                                    path={route.path}
                                    element={<Navigate to={route.index} replace />}
                                />}
                                <Route path={route.path} element={<RouteWrapper guard={route.guard} element={route.element} />}>
                                    {children?.map((r: any, i: number) => {
                                        return (
                                            <Route key={i} path={r.path} element={<RouteWrapper guard={r.guard || route.guard} element={r.element} />} />
                                        )
                                    }
                                    )}
                                </Route>
                            </React.Fragment>
                        ) : (
                            <Route
                                key={index}
                                path={route.path}
                                element={<RouteWrapper guard={route.guard} element={route.element} />}
                            />
                        )
                    )
                }
                )}
                <Route path='*' element={<Navigate replace to={token ? redirectPartner || redirectNotExistingRoutes || '/apply' : '/apply'} />} />
            </Routes>
        </div>
    );
}

// export default App;
export default Sentry.withProfiler(App);