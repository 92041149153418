import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { IItemAiAgent } from '../../interfaces/ai-agents/IItem';
import FileItem from './item-render/FileItem';
import { tryToAddNextConversationAiAgent, tryToFetchConversations } from '../../store/brokers/ai-agents/aiAgentsSlice';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import InventoryDetailsItem from './item-render/InventoryDetailsItem';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LogisticDetailsItem from './item-render/LogisticDetailsItem';
import MatchesDetailsItem from './item-render/MatchesDetailsItem';
import GetConfirmationDetailsItem from './item-render/GetConfirmationDetailsItem';
import LinkHighlighter from '../../shared/link-highlighter/LinkHighlighter';

const AiAgentsContent = ({
	type
}: {
	type?: 'seller' | 'buyer'
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const { id } = useParams();
	const aiAgentsItems = useAppSelector(state => state.aiAgents) as IItemAiAgent;
	const chatContainerRef = useRef<HTMLDivElement>(null);

	const fetchConversations = async () => {
		try {
			const response = await dispatch(tryToFetchConversations({ brokerId: id || '', type })).unwrap();
			if (response.shouldCallNextQuestion)
				await dispatch(tryToAddNextConversationAiAgent({chatId: id || '', type})).unwrap();
		} catch (error) {
			console.error({ error })
		}
	}
	useEffect(() => {
		const hasNavigated = sessionStorage.getItem('hasNavigated');
		if (hasNavigated && type) {
			navigate('/ai-agents')
		}
		else {
			fetchConversations()
			sessionStorage.setItem('hasNavigated', 'true');
		}
		return () => {
            sessionStorage.removeItem('hasNavigated');
        };
	}, [location])

	useEffect(() => {
		if (chatContainerRef.current) {
			chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
		}
	}, [aiAgentsItems.items])

	return (
		<>
			<div id='chat-container' ref={chatContainerRef} className='block flex-col w-full max-h-[calc(100vh-200px)] mb-2 justify-end overflow-y-auto mt-auto'>
				{Array.isArray(aiAgentsItems.items) && aiAgentsItems.items?.map((item, index: number) => (
					<React.Fragment key={item.requestId + '-' + item.questionId + '-' + index}>
						<TransitionGroup>
							<CSSTransition key={item?.requestId + '-' + item?.questionId + '-' + index} appear={true} timeout={index === 1 ? 1000 : 200} classNames={(item.agent ? 'fadeInUp' : 'fadeInUpFast')}>
								<div key={item.message?.toString()} className='flex flex-row mb-4'>
									{item.agent && <img alt='photo' className='w-10 h-10 mb-auto mr-4 rounded-full' src='/assets/auth/main-photo-yellow.svg' />}
									<div className={'w-[calc(100%-56px)] border rounded-xl p-5 break-words bg-white bg-opacity-50 ' +
										(item.type === 'error' ? 'border-red-500 ' : 'border-[#E0E0E0]')
									}
									>
										{item.messageType === 'default' && <p>
											<LinkHighlighter text={item.message} isLoading={aiAgentsItems.itemsAreLoading} type={type} buttons={item.buttons}/> 
										</p>}
										{(item.messageType === 'file') && <FileItem file={item.file} message={item.message} />}
										{(item.messageType === 'inventory-details' && item.inventory) && <InventoryDetailsItem isLoading={aiAgentsItems.itemsAreLoading} message={item.message} inventoryDetails={item.inventory} userHasAcceptOrReject={aiAgentsItems.userHasAcceptOrReject} type={type} buttons={item.buttons} />}
										{(item.messageType === 'inventory-details' && item.logistics) && <LogisticDetailsItem isLoading={aiAgentsItems.itemsAreLoading} message={item.message} logisticDetails={item.logistics} type={type} userHasConfirmOrder={aiAgentsItems.userHasConfirmOrder} buttons={item.buttons}  />}
										{(item.messageType === 'inventory-details' && (item.partialMatches || item.fullMatches)) && <MatchesDetailsItem isLoading={aiAgentsItems.itemsAreLoading} message={item.message} fullMatches={item.fullMatches} partialMatches={item.partialMatches} selectItemsToBuy={aiAgentsItems.selectItemsToBuy} type={type} />}
										{(item.messageType === 'inventory-details' && (item.getConfirmedItems)) && <GetConfirmationDetailsItem isLoading={aiAgentsItems.itemsAreLoading} message={item.message} getConfirmedItems={item.getConfirmedItems} confirmItemsToBuy={aiAgentsItems.confirmItemsToBuy} type={type} />}
									</div>
								</div>
							</CSSTransition>
						</TransitionGroup>
						{item.type === 'pending' &&
							<TransitionGroup>
								<CSSTransition appear={true} timeout={300} classNames="fadeInUp">
									<div key={item.message?.toString()} className='flex flex-row mb-4'>
										<img alt='photo' className='w-10 h-10 mb-auto mr-4 rounded-full' src='/assets/auth/main-photo-yellow.svg' />
										<div className='w-[calc(100%-56px)] border rounded-xl p-5 gradient-placeholder'>
											...
										</div>
									</div>
								</CSSTransition>
							</TransitionGroup>
						}
					</React.Fragment>
				))}
			</div>
			{(!aiAgentsItems.items || (Array.isArray(aiAgentsItems.items) && aiAgentsItems.items.length === 0)) &&
				(aiAgentsItems.itemsAreLoading ?
					<>
						<div className='flex flex-row mb-4'>
							<img alt='photo' className='w-10 h-10 mb-auto mr-4 rounded-full' src='/assets/auth/main-photo-yellow.svg' />
							<div className='w-[calc(100%-56px)] border rounded-xl p-5 gradient-placeholder'>
								...
							</div>
						</div>
						<div className='flex flex-row mb-4'>
							<img alt='photo' className='w-10 h-10 mb-auto mr-4 rounded-full' src='/assets/auth/main-photo-yellow.svg' />
							<div className='w-[calc(100%-56px)] border rounded-xl p-5 gradient-placeholder'>
								...
							</div>
						</div>
					</> :
					<div className='flex w-full h-full justify-center items-center'>
						Cannot find chat with this session Id
					</div>
				)}
		</>
	)
}

export default AiAgentsContent;
