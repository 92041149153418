import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IActiveMarket } from '../../../interfaces/markets/IActiveMarket';
import {
	tryToFetchOrderInsightInfo,
	// tryToFindAvoidedEmissionMonthlyData
} from '../../../store/brokers/partners/orders/ordersSlice';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import { tryToFindOperatingMarkets } from '../../../store/brokers/admin/partners/partnersSlice';
import { tryToFetchWholesaleAvailableStock } from '../../../store/brokers/shared-endpoints/wholesale-price/wholesalePriceSlice';
import { MONTHLY_DATA } from '../../../utils/constants/graph-months';
import PieChartDetails from './charts/PieChartDetails';
import LineChartDetails from './charts/LineChartDetails';
import PieChartOrderDetails from './charts/PieChartOrderDetails';
// import UnstackedLineChartDetails from './charts/UnstackedLineChartDetails';
import InfoCards from './details/InfoCards';
import { tryToCreateSessionConversations } from '../../../store/brokers/ai-agents/aiAgentsSlice';
import { useGetCurrentUserQuery } from '../../../store/user/userApi';

const cardInfoItems = [
	{ name: 'Orders to Process', stats: 0, pathname: '/orders?filter=inbound', infoText: 'Number of items received' },
	{ name: 'Returns to Process', stats: 0, pathname: '/orders?filter=review', infoText: 'Number of items in Required Return' },
	{ name: 'Available Stock', stats: 0, pathname: '/orders?filter=rejected', infoText: 'Number of available stock' },
	{ name: 'Updates', stats: 0, infoText: 'Updates' },
]

const Insights = () => {
	const {data} = useGetCurrentUserQuery();
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const [requiredReturnOrders, setRequiredReturnOrders] = useState<number>()
	const [reviewOrders, setReviewOrders] = useState<number>()
	const [rejectedOrders, setRejectedOrders] = useState<number>()
	const [userMarkets, setUserMarkets] = useState<any>()
	const [activeMarket, setActiveMarket] = useState<IActiveMarket>()
	const [marketsStats, setMarketsStats] = useState<{ totalMarkets: number, missingMarkets: number, operatingMarkets: number }>()
	const [approvedOrders, setApprovedOrders] = useState<number>()
	const [availableStock, setAvailableStock] = useState<number>()
	// const [emissionData, setEmissionData] = useState<any>()
	const [acceptedMonthly, setAcceptedMonthly] = useState<boolean>(false)
	const [loadingData, setLoadingData] = useState<any>()
	const [getAllMarkets] = useGetAllMarketsMutation()
	const activeMarketState = useAppSelector((state) => state?.partners?.partnerActiveMarket)
	const state = useAppSelector((state) => state.partners);

	const getMarketsData = async () => {
		const response = await getAllMarkets(null).unwrap()
		if (response) {
			const totalMarkets = response?.length || 0
			const operatingMarkets = userMarkets?.length || 0
			const missingMarkets = (totalMarkets - operatingMarkets)
			const missingPercentage = (missingMarkets / totalMarkets) * 100
			const operatingMarketsPercentage = (operatingMarkets / totalMarkets) * 100
			const fixedOperatingMarketsPercent: number = Number((operatingMarketsPercentage || 0).toFixed(2))
			const fixedMissingPercent: number = Number((missingPercentage || 0).toFixed(2))
			setMarketsStats({
				operatingMarkets: fixedOperatingMarketsPercent || 0,
				missingMarkets: fixedMissingPercent || 0,
				totalMarkets: totalMarkets
			})
		}
	}

	useEffect(() => {
		if (activeMarketState && (JSON.stringify(activeMarketState) !== JSON.stringify(activeMarket))) {
			setActiveMarket(activeMarketState || undefined)
		}
	}, [activeMarketState])

	// const getAvoidedEmissionData = async () => {
	//     const response: any = await dispatch(tryToFindAvoidedEmissionMonthlyData({ market: activeMarket?.market?.name })).unwrap()
	//     if (response?.data) {
	//         const formatDataEmission = response?.data?.length > 0 && response?.data?.map((item: any) => ({ ...item, name: item?.month, value: item?.totalPerMonth }))
	//         setEmissionData(formatDataEmission || [])
	//     } else {
	//         setEmissionData([])
	//     }
	// }

	const getStatsData = async () => {
		if (activeMarket?.market?.name) {
			setLoadingData(true)
			const dataFormat = {
				marketName: activeMarket?.market?.name,
			}
			const response = await dispatch(tryToFetchOrderInsightInfo(dataFormat)).unwrap()
			if (response && response?.totalInsights && response?.totalInsights?.length > 0) {
				response?.totalInsights?.map((item: any) => {
					if (item?.status === 'required_return') {
						setRequiredReturnOrders(item?.total || 0)
						setRejectedOrders(item?.total || 0)
					}
					if (item?.status === 'in_review') {
						setReviewOrders(item?.total || 0)
					}
					if (item?.status === 'accepted') {
						setApprovedOrders(item?.total || 0)
					}
					return item;
				})
			} else {
				setRejectedOrders(0)
				setRequiredReturnOrders(0)
				setApprovedOrders(0)
				setReviewOrders(0)
			}
			const formatAcceptedData: any = []
			MONTHLY_DATA?.map((item: any) => {
				const findData: any = response?.dateInsights && response?.dateInsights?.length > 0 && response?.dateInsights?.find((obj: any) => obj?.month === item?.name)
				formatAcceptedData.push({
					name: item?.label,
					data: findData?.total || 0
				})
				return item
			})
			setAcceptedMonthly(formatAcceptedData || [])

			const stockResponse: any = await dispatch(tryToFetchWholesaleAvailableStock({ marketName: activeMarket?.market?.name })).unwrap()
			setAvailableStock(stockResponse?.total || 0)
			setLoadingData(false)
			//  const responseDetails = await dispatch(tryToFetchOrderInsightDetails(dataFormat)).unwrap()
		}
	}

	useEffect(() => {
		getStatsData()
		if (!state.partnerMarkets) {
			dispatch(tryToFindOperatingMarkets(null)).unwrap()
		} else {
			const rows =
				state.partnerMarkets && Array.isArray(state.partnerMarkets)
					? state.partnerMarkets.map((r) => ({ ...r, name: r?.market?.label, label: r?.market?.name, value: r?.market?._id }))
					: [];
			setUserMarkets(rows || []);
		}
	}, [])

	useEffect(() => {
		if (userMarkets) {
			getMarketsData()
		}
	}, [userMarkets])

	useEffect(() => {
		if (state.partnerMarkets) {
			const rows =
				state.partnerMarkets && Array.isArray(state.partnerMarkets)
					? state.partnerMarkets.map((r) => ({ ...r, name: r?.market?.label, label: r?.market?.name, value: r?.market?._id }))
					: [];
			if (JSON.stringify(userMarkets) !== JSON.stringify(rows)) {
				setUserMarkets(rows || []);
			}
		}
	}, [state?.partnerMarkets])

	useEffect(() => {
		if (activeMarket) {
			getStatsData()
			// getAvoidedEmissionData()
		}
	}, [activeMarket])

	const onGoToInfoCards = (pathname?: string) => {
		navigate(pathname || '/insights')
	}

	const handleGoToSeller = useCallback(async () => {
		await dispatch(tryToCreateSessionConversations({sessionId: data?.sessionId || '', type: 'seller'})).unwrap()
		navigate(`/ai-agents/chat/sell/${data?.sessionId}`)
	}, [navigate, data]);

	console.log({ marketsStats, approvedOrders, rejectedOrders, acceptedMonthly })

	return (
		<div>
			<div>
				<p className='page-title'>Insights</p>
			</div>
			<div className='grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 sm:gap-1 md:gap-4'>
				{cardInfoItems?.map((item, index) => {
					let value: number = 0
					let color: string | undefined
					let risk
					switch (item?.name) {
						case 'Returns to Process':
							value = requiredReturnOrders || 0
							break;
						case 'Orders to Process':
							value = reviewOrders || 0
							break;
						case 'Available Stock':
							value = availableStock || 0
							break
						case 'Updates': {
							value = 0
							break;
						}
					}
					return (
						<InfoCards
							key={index}
							loadingData={loadingData}
							title={item?.name}
							infoText={item?.infoText}
							risk={risk}
							stats={value}
							color={color}
							pathname={item?.pathname}
							onGoToItem={() => onGoToInfoCards(item?.pathname)}
						/>
					)
				})
				}
			</div>
			{(Number(approvedOrders) > 0 || Number(rejectedOrders) > 0) ?
				<>
					<div className='grid sm:grid-cols-4  lg:grid-cols-8 sm:gap-2 lg:gap-4 my-5'>
						<div className='col-span-4'>
							<PieChartDetails
								marketsStats={marketsStats}
							/>
						</div>
						<div className='col-span-4'>
							<PieChartOrderDetails
								acceptedValue={approvedOrders || 0}
								rejectedValue={rejectedOrders || 0}
							/>
						</div>
					</div>
					<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 my-5'>
						<LineChartDetails
							title={'Number of Accepted Units'}
							data={acceptedMonthly}
						/>
						{/* <UnstackedLineChartDetails
							title={'Number of Units'}
							emissionData={emissionData}
						/> */}
					</div>
				</> : data?.sessionId ? <div className='w-full md:w-6/12 flex flex-wrap rounded-lg my-5'>
						<button className='!bg-gradient-to-r !from-[#fdf690] !to-[#fbd18f] hover:bg-gray-100 p-4 w-full flex flex-col justify-center items-center rounded-xl' onClick={handleGoToSeller} type='button'>
							<img alt='upload' src='/assets/ai-agents/upload-inventory.svg' />
							<p className='text-base font-bold text-[#484A4B]'>Upload your stock details</p>
						</button>
					</div> : <></>
				}
		</div>
	)
}

export default Insights;