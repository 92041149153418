import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    QUESTIONS_ANSWER_TYPE,
    QUESTION_TYPES,
    DEPENDENCIES_TYPE
} from '../../../../utils/constants/question-types';
import {
    tryToAddQuestion,
    tryToEditQuestion,
    tryToFetchSingleQuestion,
    tryToGetAllLanguages,
    tryToGetAllQuestions
} from '../../../../store/brokers/shared-endpoints/questions/questionsSlice';
import { tryToFetchSingleAssetTemplate } from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { IAssetFields, IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { IQuestion, IQuestionDependencies } from '../../../../interfaces/questions/IQuestions';
import { ISelectedValue } from '../../../../interfaces/components/ISelectedValue';
import { ILanguage } from '../../../../interfaces/languages/ILanguage';
import QuestionDescriptionModal from './QuestionDescriptionModal';
import MultipleLanguagesModal from './MultipleLanguagesModal';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Textarea from '../../../../shared/textarea';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';
import Input from '../../../../shared/input';


interface IQuestionModal {
    openQuestionModal: boolean;
    questionId?: string;
    sectors?: IAssetTemplate[];
    markets?: Array<any>;
    handleCloseQuestionModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type ISelectedField = ISelectedValue & IAssetFields;

const QuestionModal = ({
    openQuestionModal,
    questionId,
    sectors,
    markets,
    handleCloseQuestionModal,
    onSaveChanges,
}: IQuestionModal) => {
    const dispatch = useAppDispatch();
    const [question, setQuestion] = useState<string>()
    const [selectedSector, setSelectedSector] = useState<any>()
    const [selectedMarkets, setSelectedMarkets] = useState<any>();
    const [generalError, setGeneralError] = useState<string>();
    const [answers, setAnswers] = useState<any>([])
    const [allQuestions, setAllQuestions] = useState<Array<IQuestion & ISelectedValue>>()
    const [filteredQuestions, setFilteredQuestions] = useState<Array<IQuestion & ISelectedField>>()
    const [selectedType, setSelectedType] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)
    const [categoryFields, setCategoryFields] = useState<any>()
    const [mappedField, setMappedField] = useState<ISelectedField>()
    const [categoryFieldSelected, setCategoryFieldSelected] = useState<any>()
    const [dependencies, setDependencies] = useState<any>()
    const [showDrawing, setShowDrawing] = useState<boolean>(false)
    const [effectPrice, setEffectPrice] = useState<boolean>(false)
    const [showDescriptionModal, setShowDescriptionModal] = useState<boolean>(false)
    const [questionDescriptionLanguages, setQuestionDescriptionLanguages] = useState<{ [key: string]: string }>();
    const [questionDescription, setQuestionDescription] = useState<string>();
    const [selectedDependencyType, setSelectedDependencyType] = useState<ISelectedValue>()
    const [answersType, setAnswersType] = useState<ISelectedValue & { showAnswers: boolean }>()
    const [allLanguages, setAllLanguages] = useState<Array<ILanguage & ISelectedValue>>()
    const [showLanguageModal, setShowLanguageModal] = useState<{ show: boolean, isQuestion: boolean, title: string, index?: number, data?: { [key: string]: string } }>()
    const [questionLanguages, setQuestionLanguages] = useState<{ [key: string]: string }>()
    const questionDetails = useAppSelector((state) => state.questions?.questionDetails);
    const assetTemplate = useAppSelector((state) => state.assetsTemplates);

    useEffect(() => {
        if (questionId) {
            dispatch(tryToFetchSingleQuestion(questionId)).unwrap();
        }
        else {
            setSelectedSector(undefined)
            setQuestion(undefined)
            setAnswers([])
            setDependencies([])
            setAnswersType(undefined)
            setMappedField(undefined)
            setGeneralError(undefined)
            setShowDrawing(false)
            setEffectPrice(false)
            setQuestionDescription(undefined)
            setQuestionDescriptionLanguages(undefined)
        }
    }, [questionId]);

    const onGettingAllLanguages = async () => {
        try {
            const response = await dispatch(tryToGetAllLanguages()).unwrap()
            const formatResponse = response?.length > 0 && response?.map((item: ILanguage) => {
                return {
                    ...item || {},
                    value: item?.code,
                    label: item?.name
                }
            })
            setAllLanguages(formatResponse || undefined)
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        onGettingAllLanguages()
    }, [])

    useEffect(() => {
        onGettingAllLanguages()
    }, [openQuestionModal])

    const onGettingAllQuestionValues = async () => {
        try {
            const formatData = {
                categoryId: selectedSector?.value,
                servicesExists: false
            }
            const response: any = await dispatch(tryToGetAllQuestions(formatData)).unwrap()
            const formatQuestions: any = [];
            response?.primaryQuestions && response?.primaryQuestions?.length > 0 && response?.primaryQuestions?.forEach((item: IQuestion) => {
                formatQuestions.push({
                    ...item || {},
                    value: item?._id,
                    label: item?.question,
                    name: item?.question,
                    type: 'PRIMARY'
                })
            })
            response?.secondaryQuestions && response?.secondaryQuestions?.length > 0 &&
                response?.secondaryQuestions?.filter((item: IQuestion) => (item?.answers?.type) === 'SINGLE_CHOICE')?.forEach((item: IQuestion) => {
                    formatQuestions.push({
                        ...item || {},
                        value: item?._id,
                        label: item?.question,
                        name: item?.question,
                        type: 'SECONDARY'
                    })
                })
            setAllQuestions(formatQuestions?.length > 0 ? formatQuestions : undefined)
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        if (allQuestions && allQuestions?.length > 0) {
            const formatFilteredQuestions: any = []
            allQuestions?.forEach((item: IQuestion & ISelectedValue) => {
                const findIfAnyMarketsMatch = selectedMarkets?.some((obj1: { value: string }) => {
                    const matchingObj = item?.markets?.find(obj2 => obj2.id === obj1.value);
                    return matchingObj !== undefined;
                });
                if ((findIfAnyMarketsMatch && (!questionId || item?._id !== questionId)) || !selectedMarkets || selectedMarkets?.length === 0) {
                    formatFilteredQuestions.push(item)
                }
            })
            setFilteredQuestions(formatFilteredQuestions?.length > 0 ? formatFilteredQuestions : undefined)
        } else {
            setFilteredQuestions(undefined)
        }
    }, [allQuestions, selectedMarkets, questionId])

    useEffect(() => {
        if (assetTemplate.assetTemplateDetails) {
            const formatTemplates = (assetTemplate.assetTemplateDetails && assetTemplate.assetTemplateDetails.fields) && assetTemplate.assetTemplateDetails.fields?.map((item: IAssetFields) => ({ ...item || {}, label: item?.name, value: item?.name }))
            if (questionDetails?.type === 'PRIMARY' && questionDetails?.field && questionId) {
                const findField = formatTemplates && formatTemplates?.length > 0 && formatTemplates?.find((item: any) => item?.label === questionDetails?.field)
                setCategoryFieldSelected(findField)
            }
            if (questionDetails?.type === 'SECONDARY' && questionDetails?.fieldMapped && questionId) {
                const findField = (formatTemplates && formatTemplates?.length > 0) && formatTemplates?.find((item: any) => item?.label === questionDetails?.fieldMapped)
                setMappedField(findField || undefined)
            }
            if (assetTemplate?.assetTemplateDetails?.name === 'phones') {
                setCategoryFields([
                    ...formatTemplates || [],
                    {
                        id: 'color',
                        name: 'color',
                        value: 'color',
                        label: 'color',
                        extendedAssetsField: true
                    }
                ])
            } else {
                setCategoryFields(formatTemplates);
            }
        }
    }, [assetTemplate.assetTemplateDetails]);

    useEffect(() => {
        if (questionDetails && questionId) {
            setQuestion(questionDetails?.question)
            const findSector = sectors && sectors?.length > 0 && sectors?.find((item: any) => item?.value === questionDetails?.category?.id)
            setSelectedSector(findSector || undefined)
            setAnswers(questionDetails?.answers?.options)
            const findQuestionType = QUESTION_TYPES?.find((item: any) => item?.value === questionDetails?.answers?.type)
            setAnswersType(findQuestionType)
            setEffectPrice(questionDetails?.effectPrice || false)
            setQuestionDescription(questionDetails?.questionDescription)
            setQuestionDescriptionLanguages(questionDetails?.questionDescriptionLanguages)
            if (questionDetails?.dependenciesType) {
                const findDependency = DEPENDENCIES_TYPE?.find((item: ISelectedValue) => item?.value === questionDetails?.dependenciesType)
                setSelectedDependencyType(findDependency || undefined)
            }
            const formatMarkets: any = []
            questionDetails?.markets && questionDetails?.markets?.length > 0 && questionDetails?.markets?.map((item: any) => {
                const findMarket = markets && markets?.length > 0 && markets?.find((market) => market?.value === item?.id)
                if (findMarket) {
                    formatMarkets.push(findMarket)
                }
                return item
            })
            if (questionDetails?.type) {
                const findType = QUESTIONS_ANSWER_TYPE?.find((item: any) => item?.value === questionDetails?.type)
                setSelectedType(findType)
            }
            setSelectedMarkets(formatMarkets || undefined)
            setShowDrawing(questionDetails?.showDraw || false)
        } else {
            setQuestion(undefined)
            setSelectedMarkets(undefined)
            setSelectedSector(undefined)
            setAnswers([])
            setDependencies([])
            setSelectedDependencyType(undefined)
            setAnswersType(undefined)
            setMappedField(undefined)
            setShowDrawing(false)
            setEffectPrice(false)
            setSelectedType(undefined)
            setQuestionDescription(undefined)
            setQuestionDescriptionLanguages(undefined)
        }
    }, [questionDetails, questionId, openQuestionModal])

    useEffect(() => {
        if (questionId && questionDetails?.dependencies && filteredQuestions && openQuestionModal) {
            const formatDependecies = questionDetails?.dependencies?.length > 0
                && questionDetails?.dependencies?.map((item: IQuestionDependencies) => {
                    const formatQuestion = (filteredQuestions && filteredQuestions?.length > 0) && filteredQuestions?.find((question: IQuestion) => question?._id === item?.questionId)
                    return {
                        question: formatQuestion || undefined,
                        answerValues: item?.answerValues
                    }
                })
            setDependencies(formatDependecies || undefined)
        } else {
            setDependencies(undefined)
        }
    }, [questionId, questionDetails, filteredQuestions, openQuestionModal])

    const onCloseModal = () => {
        handleCloseQuestionModal();
        setSelectedSector(undefined)
        setQuestion(undefined)
        setGeneralError(undefined)
        setSelectedType(undefined)
        setSelectedMarkets(undefined)
        setAnswers([])
        setDependencies([])
        setSelectedDependencyType(undefined)
        setFilteredQuestions(undefined)
        setQuestionLanguages(undefined)
        setMappedField(undefined)
        setLoading(false)
        setEffectPrice(false)
        setShowDrawing(false)
        document.body.style.overflow = 'auto';
        setQuestionDescription(undefined)
        setQuestionDescriptionLanguages(undefined)
    };

    const onSubmit = async () => {
        if (!selectedSector || !question || !selectedType || (selectedType?.value === 'PRIMARY' && !categoryFieldSelected)) {
            setGeneralError('Please fill in fields.')
            return;
        }
        if (!answersType && selectedType?.value === 'SECONDARY') {
            setGeneralError('Please select answers type.')
            return;
        }
        if (!(answers && answers?.length > 0) && selectedType?.value === 'SECONDARY' && (answersType?.showAnswers)) {
            setGeneralError('Please add answers.')
            return;
        }
        if (mappedField && selectedType?.value === 'SECONDARY') {
            let error = false
            answers && answers?.length > 0 && answers?.forEach((item: any) => {
                if (!item?.answerMappedValue) {
                    error = true
                }
            })
            if (error) {
                setGeneralError('If \'Mapped Field\' is selected, then for each answer, you need to select the value of this mapped field.')
                return;
            }
        }
        if (selectedType?.value === 'SECONDARY' && dependencies && dependencies?.length > 0) {
            let error = false
            dependencies?.forEach((item: any) => {
                if (item?.question && (!item?.answerValues || item?.answerValues?.length <= 0)) {
                    error = true
                }
            })
            if (error) {
                setGeneralError('Please for each question selected in dependencies sections choose at least one value or delete the question.')
                return;
            }
        }
        try {
            setGeneralError(undefined)
            setLoading(true)
            let data: any = {
                question,
                questionDescription: questionDescription,
                questionDescriptionLanguages: questionDescriptionLanguages,
                assetTemplateId: selectedSector.value,
                type: selectedType?.value,
                showDraw: showDrawing,
                markets: (selectedMarkets && selectedMarkets?.length > 0) ? selectedMarkets?.map((item: any) => ({ label: item?.label, name: item?.displayName, id: item?.value })) : []
            }
            if (selectedType?.value === 'SECONDARY') {
                data = {
                    ...data || {},
                    answers: {
                        type: answersType?.value,
                        options: (answersType && answersType?.showAnswers && (answers && answers?.length > 0)) ? answers?.filter((item: any) => Object?.keys(item)?.length > 0) : null
                    },
                }
            } else {
                data = {
                    ...data || {},
                    field: categoryFieldSelected?.label,
                    extendedAssetsField: categoryFieldSelected?.extendedAssetsField || false
                }
            }
            if (dependencies && selectedType?.value === 'SECONDARY') {
                const formatDependencies = (dependencies && dependencies?.length > 0)
                    && dependencies?.filter((item: any) => (Object?.keys(item)?.length > 0))?.map((item: any) => ({ questionId: item?.question?._id, answerValues: item?.answerValues }))
                data = {
                    ...data || {},
                    dependencies: formatDependencies,
                }
            }
            if (selectedDependencyType && selectedType?.value === 'SECONDARY') {
                data = {
                    ...data || {},
                    dependenciesType: selectedDependencyType?.value,
                }
            }
            if (mappedField && selectedType?.value === 'SECONDARY') {
                data = {
                    ...data || {},
                    fieldMapped: mappedField?.name,
                }
            }
            if (selectedType?.value === 'SECONDARY' && answersType?.value === 'NUMBER_INPUT') {
                data = {
                    ...data || {},
                    effectPrice: effectPrice,
                }
            }
            if (questionId) {
                data = {
                    ...data || {},
                    _id: questionId
                }
                await dispatch(tryToEditQuestion(data)).unwrap()
            }
            else {
                await dispatch(tryToAddQuestion(data)).unwrap();
            }
            setGeneralError(undefined);
            onSaveChanges('success', 'Question added successfully!')
            onCloseModal()
        } catch (error) {
            setGeneralError(`${error}`)
        }
        setLoading(false)
    };

    const onGettingAssetTemplateFieldWithValues = async () => {
        await dispatch(tryToFetchSingleAssetTemplate(selectedSector?.value || ''));
    }

    useEffect(() => {
        if (selectedSector && selectedType?.value !== 'PRIMARY') {
            onGettingAllQuestionValues()
        }
        if (selectedSector) {
            onGettingAssetTemplateFieldWithValues()
        } else {
            setCategoryFields(undefined)
        }
        setDependencies(undefined)
        setSelectedDependencyType(undefined)
        setMappedField(undefined)
        setCategoryFieldSelected(undefined)
    }, [selectedType, selectedSector])

    useEffect(() => {
        if (selectedType?.value === 'PRIMARY') {
            setAnswers(undefined)
        }
    }, [selectedType])

    const onChangeQuestion = (e: any) => {
        e.persist();
        setQuestion(e?.target?.value);
    };

    const onChangeBreakCashout = (e: any, type?: string, index?: number) => {
        e.persist();
        const formatAnswers = (answers && answers?.length > 0) ? answers?.map((item: any, idx: number) => {
            if (index === idx) {
                return {
                    ...item || {},
                    breakCashoutDescription: e?.target?.value
                }
            } else {
                return item
            }
        }) :
            [{
                breakCashoutDescription: e?.target?.value
            }]
        setAnswers(formatAnswers)
    };

    const onSelectSector = (value: any, type?: string) => {
        setSelectedSector(value)
    }

    const onSelectMarkets = (value: any, type?: string) => {
        setSelectedMarkets(value)
    }

    const onSelectType = (value: any, type?: string) => {
        setSelectedType(value)
    }

    const onAddNewAnswer = () => {
        setAnswers([
            ...answers || [],
            {}
        ])
    }

    const onAddNewDependency = () => {
        setDependencies([
            ...dependencies || [],
            {}
        ])
    }

    const onChangeAnswerValue = (value: string | null | undefined, type?: string, index?: any) => {
        const formatAnswers = (answers && answers?.length > 0) ? answers?.map((item: any, idx: number) => {
            if (index === idx) {
                return {
                    ...item || {},
                    answer: value
                }
            } else {
                return item
            }
        }) :
            [{
                answer: value
            }]
        setAnswers(formatAnswers)
    }

    const onSelectAnswerMappedValues = (value: ISelectedValue, type?: string, index?: any) => {
        const formatAnswers = (answers && answers?.length > 0) ? answers?.map((item: any, idx: number) => {
            if (index === idx) {
                return {
                    ...item || {},
                    answerMappedValue: value ? value?.value : undefined
                }
            } else {
                return item
            }
        }) :
            [{
                answerMappedValue: value ? value?.value : undefined
            }]
        setAnswers(formatAnswers)
    }

    const onChangeDependencyQuestionSelected = (value: any, type?: string, index?: any) => {
        if (type) {
            const formatDependencies = (dependencies && dependencies?.length > 0) ? dependencies?.map((item: any, idx: number) => {
                if (index === idx) {
                    return {
                        ...item || {},
                        [type]: (value && value?.length > 0) ? value?.map((item: { value: string, label: string }) => item?.value) : value
                    }
                } else {
                    return item
                }
            }) :
                [{
                    [type]: (value && value?.length > 0) ? value?.map((item: { value: string, label: string }) => item?.value) : value
                }]
            setDependencies(formatDependencies)
        }
    }

    const onDeleteAnswer = (index: number) => {
        const filterAnswers = (answers && answers?.length > 1) ? answers?.filter((item: any, idx: number) => idx !== index) : []
        setAnswers(filterAnswers)
    }

    const onDeleteDependencie = (index: number) => {
        const filterDependencies = (dependencies && dependencies?.length > 1) ? dependencies?.filter((item: any, idx: number) => idx !== index) : []
        setDependencies(filterDependencies)
    }

    const onSelectCategoryField = (value: any, type?: string) => {
        setCategoryFieldSelected(value)
    }

    const onSelectMappedField = (value: ISelectedField | undefined, type?: string) => {
        if (!selectedSector || answersType?.value !== 'SINGLE_CHOICE') {
            return;
        }
        setMappedField(value)
        const formatAnswers = (answers && answers?.length > 0) && answers?.map((item: any, idx: number) => {
            return {
                ...item || {},
                answerMappedValue: undefined
            }
        })
        setAnswers(formatAnswers)
    }

    const onSelectAnswersType = (value: ISelectedField & { showAnswers: boolean } | undefined, type?: string) => {
        setAnswersType(value || undefined)
        if (!value?.showAnswers) {
            setMappedField(undefined)
            setAnswers([])
        }
        if (value?.value !== 'SINGLE_CHOICE') {
            setMappedField(undefined)
        }
        if (value?.value !== 'NUMBER_INPUT') {
            setEffectPrice(false)
        }
    }

    const onClickShowLanguageModal = (isQuestion: boolean, title: string, data?: { [key: string]: string }, index?: number) => {
        setShowLanguageModal({
            show: true,
            isQuestion: isQuestion,
            title: title,
            index: index,
            data: data
        })
    }

    const onSaveLanguagesChanges = (data: { [key: string]: string } | {} | undefined, isQuestion: boolean, index?: number) => {
        if (isQuestion) {
            setQuestionLanguages(data)
        } else {
            const formatAnswers = (answers && answers?.length > 0) ? answers?.map((item: any, idx: number) => {
                if (index === idx) {
                    return {
                        ...item || {},
                        answerLanguages: data
                    }
                } else {
                    return item
                }
            }) :
                [{
                    answerLanguages: data
                }]
            setAnswers(formatAnswers)
        }
    }

    const onSaveQuestionDescriptionChanges = (description?: string, descriptionLanguages?: { [key: string]: string }) => {
        setQuestionDescription(description)
        setQuestionDescriptionLanguages(descriptionLanguages)
    }

    const onChangeShowDrawing = () => {
        setShowDrawing(!showDrawing)
    }

    const onChangeValuePriceEffect = () => {
        setEffectPrice(!effectPrice)
    }

    const onChangeCashoutBreak = (status: boolean, index?: number) => {
        const formatAnswers = (answers && answers?.length > 0) ? answers?.map((item: any, idx: number) => {
            if (index === idx) {
                return {
                    ...item || {},
                    breakCashout: status,
                    breakCashoutDescription: !status ? undefined : item?.breakCashoutDesciption
                }
            } else {
                return item
            }
        }) :
            [{
                breakCashout: status,
            }]
        setAnswers(formatAnswers)
    }

    const onChangeSelectedDependenciesType = (e: ISelectedField) => {
        setSelectedDependencyType(e)
    }

    return (
        <Modal
            open={openQuestionModal}
            onClose={onCloseModal}
            showInRight={true}
            contentContainerStyle={'min-w-[650px]'}>
            <div className={'p-2 min-w-[600px]'}>
                <div className='flex flex-row items-center mb-5'>
                    <p className='mt-2 mb-4 page-title !text-[18px] font-bold mr-10'>
                        {questionId ? 'Edit Question' : 'Question Creator'}
                    </p>
                    <div>
                        <Button
                            label='Save Question'
                            dataQa={'submit'}
                            className={`${loading ? 'btn-primary-disable' : 'btn-primary'}  min-w-[200px]`}
                            onClickButton={() => !loading && onSubmit()} />
                    </div>
                </div>
                {generalError && <Error text={generalError} />}
                <div>
                    <div className='bg-[#f8f9fc] rounded p-2 mb-2'>
                        <SelectCheckbox
                            name={'Category'}
                            placeholder={' '}
                            dataQa={'selected-category'}
                            selectedOption={selectedSector}
                            options={sectors || []}
                            containerStyle='w-[250px]'
                            disabled={!!questionId}
                            uniqueName={'sector'}
                            onChangeSelectedOption={onSelectSector}
                        />
                    </div>
                    <div className='bg-[#f8f9fc] rounded p-2 mb-2'>
                        <SelectCheckbox
                            name={'Answers Type'}
                            placeholder={' '}
                            selectedOption={selectedType}
                            dataQa={'answers-type'}
                            containerStyle='w-[250px]'
                            options={QUESTIONS_ANSWER_TYPE || []}
                            uniqueName={'answerType'}
                            onChangeSelectedOption={onSelectType}
                        />
                    </div>
                    {selectedType?.value === 'PRIMARY' &&
                        <div className='bg-[#f8f9fc] rounded p-2 mb-2'>
                            <SelectCheckbox
                                name={'Category Field'}
                                placeholder={' '}
                                selectedOption={categoryFieldSelected}
                                dataQa={'category-field'}
                                options={categoryFields || []}
                                uniqueName={'categoryField'}
                                onChangeSelectedOption={onSelectCategoryField}
                            />
                        </div>
                    }
                    <div className='bg-[#f8f9fc] rounded p-2 mb-2'>
                        <div className='flex flex-row justify-start items-center min-w-[100%]'>
                            <div className='flex items-center mr-1'>
                                <input
                                    onChange={() => onChangeShowDrawing()}
                                    checked={showDrawing}
                                    id='checkbox-all-1'
                                    data-qa={'checkbox'}
                                    type='checkbox'
                                    className='w-4 h-4 !outline-none text-blue-600 bg-gray-100 border-gray-300 accent-yellow-300 rounded focus:accent-yellow-300 focus:ring-2'
                                />
                                <label htmlFor='checkbox-all-1' className='sr-only'>
                                </label>
                            </div>
                            <p className='text-[#a4a4a4]'>
                                Show Drawing for this question
                            </p>
                        </div>
                    </div>
                    <div className='bg-[#f8f9fc] rounded p-2 mb-2'>
                        <p className='block text-primary-light font-normal text-base mb-1'>Question</p>
                        <Textarea
                            name='Question'
                            containerStyle='!mb-0'
                            className={'!bg-white w-full !resize-y'}
                            rows={4}
                            dataQa={'question'}
                            value={question}
                            onChangeTextarea={onChangeQuestion}
                        />
                        <div className='flex flex-row justify-end'>
                            <Button
                                label={'Manage Question For Different Languages'}
                                className={`btn-primary-text-underline !text-[#b4b4b4] !font-medium !hover:text-[#d4d4d4] ${(!question || !allLanguages) && 'cursor-not-allowed'}`}
                                onClickButton={() => (question && allLanguages) && onClickShowLanguageModal(true, question, questionLanguages)}
                            />
                        </div>
                        <div className='flex flex-row justify-start my-3'>
                            <Button
                                label={'Manage Question Description'}
                                className='btn-main'
                                onClickButton={() => setShowDescriptionModal(true)}
                            />
                        </div>
                    </div>
                    <div className='bg-[#f8f9fc] rounded p-2 mb-2'>
                        <SelectCheckbox
                            name={'Markets'}
                            placeholder={' '}
                            dataQa={'selected-markets'}
                            selectedOption={selectedMarkets}
                            options={markets || []}
                            uniqueName={'markets'}
                            multiple={true}
                            onChangeSelectedOption={onSelectMarkets}
                        />
                    </div>
                    {selectedType &&
                        <>
                            {selectedType?.value !== 'PRIMARY' &&
                                <div>
                                    <div className='bg-[#f8f9fc] rounded p-2 mb-2'>
                                        <SelectCheckbox
                                            name={'Answers Type'}
                                            placeholder={' '}
                                            selectedOption={answersType || undefined}
                                            dataQa={'answers-type'}
                                            options={QUESTION_TYPES || []}
                                            uniqueName={'answersType'}
                                            onChangeSelectedOption={onSelectAnswersType}
                                        />
                                        {(answersType && answersType?.value === 'NUMBER_INPUT') &&
                                            <div className='flex flex-row justify-start items-center min-w-[100%] mb-2'>
                                                <div className='flex items-center mr-1'>
                                                    <input
                                                        onChange={() => onChangeValuePriceEffect()}
                                                        checked={effectPrice}
                                                        id='checkbox-all-1'
                                                        data-qa={'checkbox'}
                                                        type='checkbox'
                                                        className='w-4 h-4 !outline-none text-blue-600 bg-gray-100 border-gray-300 accent-yellow-300 rounded focus:accent-yellow-300 focus:ring-2'
                                                    />
                                                    <label htmlFor='checkbox-all-1' className='sr-only'>
                                                    </label>
                                                </div>
                                                <p className='text-[#a4a4a4]'>
                                                    Value will effect the price
                                                </p>
                                            </div>
                                        }
                                    </div>
                                    <div className='bg-[#f8f9fc] rounded p-2 mb-2'>
                                        <SelectCheckbox
                                            name={'Mapped With Field (optional and available only for Single Choice answers type)'}
                                            placeholder={' '}
                                            disabled={!selectedSector || answersType?.value !== 'SINGLE_CHOICE' || false}
                                            selectedOption={mappedField}
                                            dataQa={'mapped-field'}
                                            options={categoryFields || []}
                                            uniqueName={'mappedField'}
                                            onChangeSelectedOption={onSelectMappedField}
                                        />
                                    </div>
                                    <div className='bg-[#f8f9fc] rounded p-2 mb-2'>
                                        <p className='block text-primary-light font-normal text-base mt-4 mb-2'>Answers (Available only for secondary type questions)</p>
                                        {(answers && answers?.length > 0) && answers?.map((item: any, index: number) => {
                                            return (
                                                <div key={index} className='mb-5'>
                                                    <p className='font-semibold'>Answer {index + 1}</p>
                                                    <div
                                                        key={index}
                                                        className='flex flex-row justify-start items-center'>
                                                        <Input
                                                            placeholder=' '
                                                            inputValue={answers?.[index]?.answer}
                                                            dataQa={'answer'}
                                                            onChangeInput={onChangeAnswerValue}
                                                            inputUniqueName='answer'
                                                            containerStyle='min-w-[85%]'
                                                            index={index}
                                                            showValue={true}
                                                        />
                                                        <div data-qa={'delete-answer'} className='max-w-[12%] ml-5 pt-5 cursor-pointer' onClick={() => onDeleteAnswer(index)}>
                                                            <img src={'/assets/shared/trash-gray.svg'} className={'w-[15px] mb-4 hover:w-[17px] object-contain'} />
                                                        </div>
                                                    </div>

                                                    {(answersType && answersType?.value === 'SINGLE_CHOICE') &&
                                                        <>
                                                            <div className='flex flex-row justify-start items-center min-w-[100%] mb-2'>
                                                                <div className='flex items-center mr-1'>
                                                                    <input
                                                                        onChange={() => onChangeCashoutBreak(!answers?.[index]?.breakCashout, index)}
                                                                        checked={answers?.[index]?.breakCashout}
                                                                        id='checkbox-all-1'
                                                                        data-qa={'checkbox'}
                                                                        type='checkbox'
                                                                        className='w-4 h-4 !outline-none text-blue-600 bg-gray-100 border-gray-300 accent-yellow-300 rounded focus:accent-yellow-300 focus:ring-2'
                                                                    />
                                                                    <label htmlFor='checkbox-all-1' className='sr-only'>
                                                                    </label>
                                                                </div>
                                                                <p className='text-[#a4a4a4]'>
                                                                    Break Cashout if this option selected
                                                                </p>
                                                            </div>
                                                            {answers?.[index]?.breakCashout &&
                                                                <>
                                                                    <p className='block text-primary-light font-normal text-base mb-1'>Break Cashout Description</p>
                                                                    <Textarea
                                                                        name=' '
                                                                        containerStyle='!mb-0'
                                                                        className={'!bg-white w-full !resize-y'}
                                                                        rows={4}
                                                                        dataQa={'breakCashout'}
                                                                        textareaType={'breakCashout'}
                                                                        index={index}
                                                                        value={answers?.[index]?.breakCashoutDescription}
                                                                        onChangeTextarea={onChangeBreakCashout}
                                                                    />
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                    <div className='flex flex-row justify-end'>
                                                        <Button
                                                            label={'Manage Answer For Different Languages'}
                                                            className={`btn-primary-text-underline !text-[#b4b4b4] !font-medium !hover:text-[#d4d4d4] ${(!answers?.[index]?.answer || !allLanguages) && 'cursor-not-allowed'}`}
                                                            onClickButton={() => (answers?.[index]?.answer && allLanguages) && onClickShowLanguageModal(false, answers?.[index]?.answer, answers?.[index]?.answerLanguages, index)}
                                                        />
                                                    </div>
                                                    {mappedField &&
                                                        <div>
                                                            <SelectCheckbox
                                                                name={'Mapped With Field Value'}
                                                                placeholder={' '}
                                                                selectedOption={answers?.[index]?.answerMappedValue ? { label: answers?.[index]?.answerMappedValue, value: answers?.[index]?.answerMappedValue } : undefined}
                                                                dataQa={'mapped-values'}
                                                                index={index}
                                                                options={mappedField?.values ? mappedField?.values?.map((mappedValue: string) => ({ label: mappedValue, value: mappedValue })) : []}
                                                                uniqueName={'mappedValues'}
                                                                onChangeSelectedOption={onSelectAnswerMappedValues}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })}
                                        <Button
                                            label={'Add Answer'}
                                            dataQa={'add-answer'}
                                            onClickButton={() => answersType?.showAnswers && onAddNewAnswer()}
                                            className={`${answersType?.showAnswers ? 'btn-main' : 'btn-main-disable'} mb-5 mt-2`} />
                                    </div>
                                    <div className='bg-[#f8f9fc] rounded p-2 mb-2'>
                                        <p className='block text-primary-light font-bold text-base mt-4 mb-2'>Manage Dependencies (Available only for secondary type questions)</p>
                                        <div>
                                            <SelectCheckbox
                                                name='Dependencies Type'
                                                placeholder=' '
                                                selectedOption={selectedDependencyType}
                                                containerStyle='min-w-[85%]'
                                                options={DEPENDENCIES_TYPE}
                                                onChangeSelectedOption={onChangeSelectedDependenciesType}
                                            />
                                        </div>
                                        {(dependencies && dependencies?.length > 0) && dependencies?.map((item: any, index: number) => {
                                            let values = []
                                            if (dependencies?.[index]?.question) {
                                                if (dependencies?.[index]?.question?.type === 'PRIMARY') {
                                                    const findValues = categoryFields?.find((fields: IAssetFields) => fields?.name === dependencies?.[index]?.question?.field)
                                                    values = (findValues?.values && findValues?.values?.length > 0) ? findValues?.values?.map((value: string) => ({ label: value, value: value })) : []
                                                } else {
                                                    const formatAnswers = dependencies?.[index]?.question?.answers?.options && dependencies?.[index]?.question?.answers?.options?.length > 0
                                                        && dependencies?.[index]?.question?.answers?.options?.map((answer: any) => {
                                                            return {
                                                                name: answer?.answer,
                                                                value: answer?.answer,
                                                                label: answer?.answer
                                                            }
                                                        })
                                                    values = formatAnswers || []
                                                }
                                            }
                                            let filteredAllQuestions = (filteredQuestions && filteredQuestions?.length > 0 && dependencies && dependencies?.length > 0) ?
                                                filteredQuestions?.filter(item1 => {
                                                    return !dependencies?.some((item2: any) => item2.question?._id === item1._id);
                                                })
                                                : filteredQuestions
                                            if (filteredQuestions && filteredQuestions?.length > 0 && dependencies && dependencies?.length > 0 && dependencies?.[index]?.question) {
                                                filteredAllQuestions = [
                                                    dependencies?.[index]?.question,
                                                    ...filteredAllQuestions || [],
                                                ]
                                            }
                                            return (
                                                <div key={index}>
                                                    <div
                                                        key={index}
                                                        className='flex flex-row justify-start items-center'>
                                                        <SelectCheckbox
                                                            name={`Question Dependency ${index + 1}`}
                                                            placeholder=' '
                                                            selectedOption={dependencies?.[index]?.question}
                                                            dataQa={'dependency-question'}
                                                            options={filteredAllQuestions || []}
                                                            onChangeSelectedOption={onChangeDependencyQuestionSelected}
                                                            uniqueName='question'
                                                            containerStyle='min-w-[85%]'
                                                            index={index}
                                                        />
                                                        <div data-qa={'delete-dependencie'} className='max-w-[12%] ml-5 pt-5 cursor-pointer' onClick={() => onDeleteDependencie(index)}>
                                                            <img src={'/assets/shared/trash-gray.svg'} className={'w-[15px] mb-4 hover:w-[17px] object-contain'} />
                                                        </div>
                                                    </div>
                                                    {dependencies?.[index]?.question &&
                                                        <div>
                                                            <SelectCheckbox
                                                                name={'Field Answers Dependencies'}
                                                                placeholder={' '}
                                                                selectedOption={dependencies?.[index]?.answerValues && dependencies?.[index]?.answerValues ? dependencies?.[index]?.answerValues?.map((value: string) => ({ label: value, value: value })) : undefined}
                                                                dataQa={'field-answers-dependencies'}
                                                                index={index}
                                                                multiple={true}
                                                                options={values}
                                                                uniqueName={'answerValues'}
                                                                onChangeSelectedOption={onChangeDependencyQuestionSelected}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })}
                                        {(!filteredQuestions || ((filteredQuestions?.length || 0) > (dependencies?.length || 0))) &&
                                            <Button
                                                label={'Add New Dependency'}
                                                dataQa={'add-dependency'}
                                                onClickButton={() => (selectedSector && filteredQuestions) && onAddNewDependency()}
                                                className={`${(selectedSector && filteredQuestions) ? 'btn-main' : 'btn-main-disable cursor-not-allowed'} mb-5 mt-2`} />
                                        }
                                    </div>
                                </div>
                            }
                        </>
                    }
                    {(selectedType?.value === 'SECONDARY') &&
                        < div className='flex flex-row justify-center my-4'>
                            <Button
                                label='Save Question'
                                dataQa={'submit'}
                                className={`${loading ? 'btn-primary-disable' : 'btn-primary'}  min-w-[200px]`}
                                onClickButton={() => !loading && onSubmit()} />
                        </div>
                    }
                </div>
                {(showLanguageModal && showLanguageModal?.show && allLanguages) &&
                    <MultipleLanguagesModal
                        openModal={showLanguageModal?.show}
                        title={showLanguageModal?.title || ''}
                        isQuestion={showLanguageModal?.isQuestion || false}
                        data={showLanguageModal?.data}
                        index={showLanguageModal?.index}
                        languages={allLanguages}
                        handleCloseModal={() => setShowLanguageModal(undefined)}
                        onSaveLanguagesChanges={onSaveLanguagesChanges}
                    />
                }
                {showDescriptionModal &&
                    <QuestionDescriptionModal
                        openModal={showDescriptionModal}
                        data={questionDescriptionLanguages}
                        description={questionDescription}
                        languages={allLanguages}
                        handleCloseModal={() => setShowDescriptionModal(false)}
                        onSaveLanguagesChanges={onSaveQuestionDescriptionChanges}
                    />
                }
            </div>
        </Modal >
    );
};

export default QuestionModal;
