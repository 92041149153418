import React, { useCallback } from 'react';
import Button from '../button';
import { tryToAddConversationAiAgent, tryToAddNextConversationAiAgent } from '../../store/brokers/ai-agents/aiAgentsSlice';
import { useAppDispatch } from '../../app/hooks';
import { useParams } from 'react-router-dom';

const LinkHighlighter = ({ text, buttons, isLoading, type }: { text: string, buttons?: string[], isLoading?: boolean, type?: 'seller' | 'buyer' }) => {
	const { id } = useParams();
	const urlRegex = /(https?:\/\/[^\s]+)/g;
	const dispatch = useAppDispatch();
	const parts = text.split(urlRegex).map((part, index) => {
		if (urlRegex.test(part)) {
			return (
				<a key={index} className='text-blue-500 underline' href={part} target="_blank" rel="noopener noreferrer">
					click here
				</a>
			);
		}
		return part;
	});

	const handleConfirm = useCallback(async (answer: string) => {
		const addChat = await dispatch(tryToAddConversationAiAgent({ answer, chatId: id || '', type, userHasConfirmOrder: true })).unwrap();
		if (addChat.success && !addChat.finalStep) {
			await dispatch(tryToAddNextConversationAiAgent({ chatId: id || '', type })).unwrap();
		}
	}, [id]);

	return <div>
		{parts}
		{buttons?.map((button, index) =>
			<td key={button} className='pt-5'>
				<Button
					disabled={isLoading}
					loading={isLoading}
					className={'text-sm font-normal hover:underline mr-3 ' + ((index % 2 === 0) ? 'text-[#00B67A]' : 'text-[#E50000]')}
					label={button}
					onClickButton={() => handleConfirm(button)}
				/>
			</td>
		)}
	</div>;
};

export default LinkHighlighter;