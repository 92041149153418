import React, { useEffect, useState } from 'react';
import { IAssets } from '../../../../interfaces/assets/IAssets';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IAssetFields } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayloadWithId } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';
import Button from '../../../../shared/button';


interface IAssetDetailsTable {
    rows: IAssets[];
    rowsLoading: boolean;
    assetTemplateFields: IAssetFields[];
    paginationData?: IPage;
    paginationState?: IPaginationPayloadWithId;
    accessControl?: IAccessControl;
    actions?: IPageActions;
    selectedRows?: Array<string>;
    showExtended?: boolean;
    onDeleteAssetRequest: (id: string) => void;
    onEditAsset: (id: string) => void;
    onClickSelectRow: (id?: string) => void;
    onChangeAssetStatus: (assetId: string, status: string, extended: boolean) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayloadWithId) => void;
    onClickAddAssetsExtendedModal: (name: string) => void;
}

const AssetDetailsTable = ({
    rows,
    rowsLoading,
    paginationData,
    assetTemplateFields,
    paginationState,
    accessControl,
    actions,
    selectedRows,
    showExtended,
    onClickAddAssetsExtendedModal,
    onDeleteAssetRequest,
    setPaginationState,
    onChangeAssetStatus,
    onClickSelectRow,
    onEditAsset
}: IAssetDetailsTable) => {
    const [tableColumns, setTableColumns] = useState<Array<string>>(['Status', 'Manage Status', 'Actions'])
    const [tableExtraKeys, setTableExtraKeys] = useState<Array<string>>()

    useEffect(() => {
        if (assetTemplateFields && assetTemplateFields?.length > 0) {
            const tableColumnsFormat = assetTemplateFields?.map((field) => field?.name)
            const formatTableExtraKeys: any = assetTemplateFields?.map((field) => field?.name)
            tableColumnsFormat.push('Status')
            tableColumnsFormat.push('Manage Status')
            if (showExtended) {
                tableColumnsFormat?.push('Add Asset Extended')
            }
            tableColumnsFormat.push('Actions')
            setTableExtraKeys(formatTableExtraKeys)
            setTableColumns(tableColumnsFormat)
        }
    }, [assetTemplateFields, showExtended])

    return (
        <Table
            selectRows={true}
            areAllRowsSelected={(selectedRows && rows && paginationData?.size ? (selectedRows?.length === (paginationData?.size * paginationData?.number)) : false)}
            columns={tableColumns}
            paginationData={paginationData}
            onClickSelectAll={() => onClickSelectRow()}
            setPaginationState={setPaginationState}
            paginationState={paginationState}
            containerStyle={'mt-0'}>
            {(!tableColumns || rowsLoading) ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: IAssets, idx: number) => {
                        const findIfRowIsSelected = selectedRows && selectedRows?.length > 0 && selectedRows?.find((item) => item === row?.id)
                        return (
                            <tr key={idx} data-qa={'broker-qa'} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>
                                    <div className='flex items-center'>
                                        <input
                                            onChange={() => onClickSelectRow(row?._id || '')}
                                            checked={!!findIfRowIsSelected}
                                            id='checkbox-all'
                                            data-qa={'checkbox'}
                                            type='checkbox'
                                            className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2' />
                                        <label htmlFor='checkbox-all' className='sr-only'>checkbox</label>
                                    </div>
                                </td>
                                {tableExtraKeys && tableExtraKeys?.length > 0 && tableExtraKeys?.map((column: any, id: number) => {
                                    return (
                                        <td key={id} className='px-6 py-4'>{(row?.descriptor?.[column] !== true && row?.descriptor?.[column] !== false) ? row?.descriptor?.[column] : (row?.descriptor?.[column] ? 'True' : 'False')}</td>
                                    )
                                })}
                                <td className='px-6 py-4'>{row?.status ? `${row?.status[0].toUpperCase()}${row?.status?.slice(1)?.toLowerCase()}` : 'Enabled'}</td>
                                <td className='px-6 py-2 min-w-[130px]'>
                                    <Button
                                        className={(!accessControl || actions?.['Change Status']) ? 'btn-main' : 'btn-main-disable'}
                                        label='Change Status'
                                        onClickButton={() => (!accessControl || actions?.['Change Status']) && onChangeAssetStatus(row?._id || '', row?.status || 'enabled', row?.extendedAsset !== false)}
                                    />
                                </td>
                                {showExtended &&
                                    <td className='px-6 py-2 min-w-[130px]'>
                                        <Button
                                            label={'Asset Extended'}
                                            onClickButton={() => ((!accessControl || actions?.['Manage Asset Extended']) && row?.extendedAsset === false) && onClickAddAssetsExtendedModal(row?.descriptor?.model || '')}
                                            className={((!accessControl || actions?.['Manage Asset Extended']) && row?.extendedAsset === false) ? 'btn-filters !rounded' : 'btn-noactive-filters cursor-not-allowed !rounded'}
                                        />
                                    </td>
                                }
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-center'>
                                        <div data-qa={'edit-asset'} onClick={() => (!accessControl || actions?.['Edit Asset']) && onEditAsset(row?._id || '')}>
                                            <img title='Edit Asset' alt='edit item' src={'/assets/table-actions/pen.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Edit Asset']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                        </div>
                                        <div data-qa={'delete-asset'} onClick={() => (!accessControl || actions?.['Delete Asset']) && onDeleteAssetRequest(row?._id || '')}>
                                            <img title='Delete Asset' alt='delete item' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Delete Asset']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-1`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table >
    )
}

export default AssetDetailsTable;