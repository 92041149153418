import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import Button from '../../../../shared/button';


interface IDownloadSampleItems {
    allFields: any;
    item: any
}

const DownloadSampleItems = ({
    allFields,
    item
}: IDownloadSampleItems) => {
    const [loadingDownload, setLoadingDownload] = useState<boolean>();
    const csvLink = React.useRef() as React.MutableRefObject<any>;

    const onDownloadTemplate = async () => {
        setLoadingDownload(true)
        csvLink.current.link.click()
        setLoadingDownload(false)
    }

    return (
        <>
            <Button
                label={loadingDownload ? 'Loading...' : 'Download Sample'}
                className='btn-main'
                onClickButton={() => !loadingDownload && onDownloadTemplate()}
            />
            <CSVLink
                ref={csvLink}
                data={[]}
                headers={(allFields && allFields?.length > 0) ? allFields : []}
                className={'none'}
                target={'_blank'}
                filename={`${item?.name}_items_sample.csv`}>
            </CSVLink>
        </>
    )
}
export default DownloadSampleItems;