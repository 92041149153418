import React, { useState, useCallback, useEffect } from 'react';
import Papa from 'papaparse';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { VAT_TYPE_ITEMS } from '../../../../utils/constants/vat-type';
import { tryToFetchAssetTemplateFieldsFilter } from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { tryToImportBatchPurchase } from '../../../../store/brokers/partners/purchase-orders/purchaseOrdersSlice';
import ImportCategoryFile from '../details/ImportCategoryFile';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Switch from '../../../../shared/switch';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';

interface IImportOrderModal {
    openImportModal: boolean;
    categories?: any;
    handleCloseModal: () => void;
    onSuccessfullyUploadItems: () => void;
}

const ImportOrderModal = ({
    openImportModal,
    categories,
    handleCloseModal,
    onSuccessfullyUploadItems
}: IImportOrderModal) => {
    const dispatch = useAppDispatch();
    const [importError, setImportError] = useState<string>();
    const [stockSelected, setStockSelected] = useState<any>();
    const [categoriesSelected, setCategoriesSelected] = useState<any>();
    const [totalPrice, setTotalPrice] = useState<any>();
    const [templateFields, setTemplateFields] = useState<any>();
    const [inventoryFields, setInventoryFields] = useState<any>();
    const [extendedFields, setExtendedFields] = useState<any>();
    const [importedSuccessfully, setImportedSuccessfully] = useState<boolean>(false)
    const [fileSelected, setFileSelected] = useState<any>();
    const [negotiate, setNegotiate] = useState<boolean>(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [showTotalPrice, setShowTotalPrice] = useState<boolean>(false);
    const activeMarketState = useAppSelector((state) => state?.partners?.partnerActiveMarket);

    const onDrop = useCallback((acceptedFiles: any, categoryId: string) => {
        setFileSelected((prevState: any) => ({
            ...prevState,
            [categoryId]: acceptedFiles?.[0],
        }));
    }, []);

    const onCloseModal = () => {
        handleCloseModal();
        setImportedSuccessfully(false);
        setLoadingSubmit(false)
        setImportError(undefined);
        setFileSelected(undefined);
        setStockSelected(undefined)
        setTotalPrice(undefined)
        setTemplateFields(undefined)
        setInventoryFields(undefined)
        setCategoriesSelected(undefined)
        setExtendedFields(undefined)
        setNegotiate(false)
    }

    const onGettingAllAssetFields = async () => {
        if (categoriesSelected && categoriesSelected?.length > 0) {
            try {
                await Promise.all(categoriesSelected && categoriesSelected?.length > 0 && categoriesSelected?.map(async (item: any) => {
                    if (!templateFields?.[item?.name]) {
                        const response: any = await dispatch(tryToFetchAssetTemplateFieldsFilter(item?.id)).unwrap();
                        if (response?.fields) {
                            setTemplateFields((prevTemplateFields: any) => ({
                                ...prevTemplateFields || {},
                                [item?.id || '']: response?.fields,
                            }));
                            setExtendedFields((prevFields: any) => ({
                                ...prevFields || {},
                                [item?.id || '']: response?.extendedFields,
                            }));
                            setInventoryFields((prevInventoryFields: any) => ({
                                ...prevInventoryFields || {},
                                [item?.id || '']: response?.inventoryFields
                            }));
                        }
                    }
                    return item;
                }))
            } catch (err) {
                // error here
            }
        }
    };

    useEffect(() => {
        if (categoriesSelected) {
            onGettingAllAssetFields()
        }
    }, [categoriesSelected])

    const onChangeShowTotalPrice = () => {
        setShowTotalPrice(!showTotalPrice)
    }

    const onUploadFile = async () => {
        setLoadingSubmit(true)
        try {
            const items: any = [];
            await Promise.all(
                categoriesSelected?.map(async (item: any) => {
                    if (fileSelected?.[item?.id]) {
                        await new Promise<void>((resolve) => {
                            Papa.parse(fileSelected?.[item?.id], {
                                header: true,
                                skipEmptyLines: true,
                                complete: (results: any) => {
                                    if (results?.data && results?.data?.length > 0) {
                                        results?.data?.forEach((data: any) => {
                                            if (data?.quantity && (Number(data?.quantity || 0) > 0)) {
                                                let templateFieldsFormated = {}
                                                let extendedFieldsFormated = {}
                                                let inventoryFieldsFormated = {}
                                                templateFields?.[item?.id] && templateFields?.[item?.id]?.length > 0 && templateFields?.[item?.id]?.map((field: any) => {
                                                    if (field?.name) {
                                                        templateFieldsFormated = {
                                                            ...templateFieldsFormated || {},
                                                            [field?.name]: data?.[field?.name] && typeof data?.[field?.name] === 'string' ? (data?.[field?.name]).trim() : data?.[field?.name]
                                                        }
                                                    }
                                                    return field
                                                })
                                                extendedFields?.[item?.id] && extendedFields?.[item?.id]?.length > 0 && extendedFields?.[item?.id]?.map((field: any) => {
                                                    if (field?.name) {
                                                        extendedFieldsFormated = {
                                                            ...extendedFieldsFormated || {},
                                                            [field?.name]: data?.[field?.name] && typeof data?.[field?.name] === 'string' ? (data?.[field?.name]).trim() : data?.[field?.name]
                                                        }
                                                    }
                                                    return field
                                                })
                                                inventoryFields?.[item?.id] && inventoryFields?.[item?.id]?.length > 0 && inventoryFields?.[item?.id]?.map((field: any) => {
                                                    if (field?.name) {
                                                        inventoryFieldsFormated = {
                                                            ...inventoryFieldsFormated || {},
                                                            [field?.name]: data?.[field?.name] && typeof data?.[field?.name] === 'string' ? (data?.[field?.name]).trim() : data?.[field?.name]
                                                        }
                                                    }
                                                    return field
                                                })
                                                items.push({
                                                    descriptor: {
                                                        ...templateFieldsFormated || {},
                                                        ...extendedFieldsFormated || {},
                                                    },
                                                    inventory: inventoryFieldsFormated,
                                                    price: data?.price ? Number(data?.price || 0) : undefined,
                                                    qty: Number(data?.quantity || 0),
                                                    categoryId: item?.id,
                                                    currency: activeMarketState?.currency?.descriptor?.name
                                                });
                                            }
                                        });
                                    }
                                    resolve();
                                },
                            });
                        });
                    }
                    return item;
                })
            );

            const payload = {
                categories: (categoriesSelected && categoriesSelected?.length > 0) && categoriesSelected?.map((item: any) => ({ id: item?.id, code: item?.name })),
                items: items || [],
                totalPrice: Number(totalPrice || 0),
                marketId: activeMarketState?.market?._id,
                negotiateOffer: negotiate,
                stockVat: stockSelected?.value,
            }
            await dispatch(tryToImportBatchPurchase(payload)).unwrap();
            onSuccessfullyUploadItems()
            setImportError(undefined)
            onCloseModal()
            // setImportedSuccessfully(true)
        } catch (err) {
            setImportedSuccessfully(false)
            setImportError(`${err}`)
        }
        setLoadingSubmit(false)
    }

    const onValidateImport = () => {
        setLoadingSubmit(true)
        if (!stockSelected || !(categoriesSelected && categoriesSelected?.length > 0) || !fileSelected) {
            setImportError('Please fill all data and import at least one file to continue!')
            setLoadingSubmit(false)
            return;
        }
        let foundError: boolean = false
        categoriesSelected?.forEach((item: any) => {
            if (!fileSelected?.[item?.id]) {
                foundError = true
            }
        })
        if (foundError) {
            setImportError('Please upload file for each selected category!')
            setLoadingSubmit(false)
            return;
        }
        setImportError(undefined)
        onUploadFile()
    }

    const onChangeSelectedVat = (e: any, type?: string) => {
        setStockSelected(e)
    }

    const onChangeCategoriesSelected = (e: any, type?: string) => {
        setCategoriesSelected(e)
    }

    const onChangeTotalPrice = (value: any, type?: string) => {
        setTotalPrice(/^\d*\.?\d*$/.test(value) ? value : totalPrice)
    }

    const onEnableNegotiations = () => {
        setNegotiate(!negotiate)
    }

    return (
        <Modal
            open={openImportModal}
            showInRight={true}
            title='Upload Items by CSV'
            contentContainerStyle='!min-w-[700px]'
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[650px]'}>
                {importedSuccessfully ?
                    <div className={'w-full flex flex-col items-center justify-between'}>
                        <div>
                            <p className='text-md font-medium'>Order has been successfully imported.</p>
                        </div>
                        <img src={'/assets/shared/success-mark.svg'} className={'h-[100px] object-contain my-10'} />
                        <Button
                            label='Close'
                            className='btn-primary-rounded-less my-3 min-w-[150px] !shadow-none'
                            onClickButton={handleCloseModal}
                        />
                    </div>
                    : <div>
                        <div className='border-b-2 border-[#e4e4e4] mb-4'>
                            <p className='text-sm font-semibold'>Import CSV File</p>
                        </div>
                        {importError && <Error text={importError} />}
                        <div className='bg-[#f8f9fc] rounded-[6px] p-3'>
                            <SelectCheckbox
                                name='Stock VAT'
                                containerStyle='max-w-[90%]'
                                options={VAT_TYPE_ITEMS}
                                selectedOption={stockSelected}
                                onChangeSelectedOption={onChangeSelectedVat}
                            />
                            <SelectCheckbox
                                name='Categories'
                                containerStyle='max-w-[90%]'
                                options={categories || []}
                                multiple={true}
                                selectedOption={categoriesSelected}
                                onChangeSelectedOption={onChangeCategoriesSelected}
                            />
                            {categoriesSelected && categoriesSelected?.length > 0 && categoriesSelected?.map((item: any, idx: number) => {
                                return (
                                    <div key={idx} className='flex flex-row my-4'>
                                        <p className='mr-3 font-bold mt-2'>{item?.label}:</p>
                                        <ImportCategoryFile
                                            categoryId={item?.id || ''}
                                            onDrop={onDrop}
                                        />
                                        {fileSelected?.[item?.id] && <p className='mt-2 mb-3 truncate'>{fileSelected?.[item?.id]?.name}</p>}
                                    </div>
                                )
                            })}
                            {categoriesSelected && categoriesSelected?.length > 0 &&
                                <div>
                                    <div className='flex flex-row justify-start items-center mb-6 min-w-[100%] mt-5 mb-3'>
                                        <div className='flex items-center mr-2'>
                                            <input
                                                onChange={() => onChangeShowTotalPrice()}
                                                checked={showTotalPrice}
                                                id='checkbox-all-1'
                                                data-qa={'checkbox'}
                                                type='checkbox'
                                                className='w-4 h-4 !outline-none text-blue-600 bg-gray-100 border-gray-300 accent-yellow-300 rounded focus:accent-yellow-300 focus:ring-2' />
                                            <label htmlFor='checkbox-all-1' className='sr-only'>
                                            </label>
                                        </div>
                                        <div>
                                            <p className='font-bold'>Set a batch price for the total purchase order.</p>
                                            <p className='text-[#a4a4a4]'>By doing this you don&apos;t need to add prices on csv file.</p>
                                        </div>
                                    </div>
                                    <Input
                                        label={`Amount (${activeMarketState?.currency?.descriptor?.name})`}
                                        showValue={true}
                                        containerStyle='max-w-[90%]'
                                        disabled={!showTotalPrice}
                                        inputValue={totalPrice}
                                        onChangeInput={onChangeTotalPrice}
                                    />
                                </div>
                            }
                            <div className='flex flex-row items-center my-4 justify-between max-w-[90%]'>
                                <p className='font-semibold mr-4'>Would you be willing to engage in negotiations?</p>
                                <Switch
                                    checked={negotiate}
                                    onToggleSwitch={() => onEnableNegotiations()}
                                    dataQa={'enabled-negotiation'}
                                />
                            </div>
                            <div className='flex flex-row items-center justify-center my-4'>
                                <Button
                                    label={'Close'}
                                    className='btn-primary-rounded-less mr-4 min-w-[120px] !shadow-none min-w-[150px]'
                                    onClickButton={onCloseModal}
                                />
                                <Button
                                    label='Submit'
                                    onClickButton={() => fileSelected && !loadingSubmit && onValidateImport()}
                                    className={`${fileSelected && !loadingSubmit ? 'btn-primary-rounded-less' : 'btn-primary-rounded-less-disable'} !shadow-none min-w-[150px]`}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </Modal>
    )
}

export default ImportOrderModal;
