import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { IActiveMarket } from '../../../interfaces/markets/IActiveMarket';
import { ISelectedValue } from '../../../interfaces/components/ISelectedValue';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { tryToFetchUserData } from '../../../store/brokers/admin/partners/partnersSlice';
import {
    tryToDeletePurchase,
    tryToFetchPurchases,
    tryToSubmitPurchase
} from '../../../store/brokers/partners/purchase-orders/purchaseOrdersSlice';
import PurchaseOrdersTable from './tables/PurchaseOrdersTable';
import PurchaseDetailsModal from './modals/PurchaseDetailsModal';
import DownloadSampleModal from './modals/DownloadSampleModal';
import ConfirmDeleteModal from './modals/ConfirmDeleteModal';
import OffersHistoryModal from './modals/OffersHistoryModal';
import ConfirmSubmitModal from './modals/ConfirmSubmitModal';
import AllCategoriesModal from './modals/AllCategoriesModal';
import ImportOrderModal from './modals/ImportOrderModal';
import OfferModal from './modals/OfferModal';
import PurchaseFilters from './details/PurchaseFilters';
import Button from '../../../shared/button';
import Input from '../../../shared/input';
import Toast from '../../../shared/toast';

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PurchaseOrders = () => {
    const query = useQuery();
    const navigator = useNavigate();
    const dispatch = useAppDispatch();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [search, setSearch] = useState<string>();
    const [rows, setRows] = useState<Array<any>>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [brokerCategories, setBrokerCategories] = useState<any>();
    const [selectedCategory, setSelectedCategory] = useState<any>();
    const [selectedStatus, setSelectedStatus] = useState<any>();
    const [purchaseId, setPurchaseId] = useState<string>();
    const [showSubmitModal, setShowSubmitModal] = useState<boolean>(false);
    const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [activeMarket, setActiveMarket] = useState<IActiveMarket>();
    const [pageAccess, setPageAccess] = useState<any>();
    const [itemsData, setItemsData] = useState<any>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
    const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [showImportModal, setShowImportModal] = useState<boolean>(false);
    const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
    const [showOfferModal, setShowOfferModal] = useState<{ show: boolean, item: any }>();
    const [offersHistoryModal, setOffersHistoryModal] = useState<{ show: boolean, offers: any }>();
    const [showCategoriesModal, setShowCategoriesModal] = useState<{ show: boolean, data: any }>()
    const activeMarketState = useAppSelector((state) => state?.partners?.partnerActiveMarket);
    const paginationData = useAppSelector((state) => state?.purchaseOrders?.purchases?.data?.page);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const purchaseLoading = useAppSelector((state) => state.purchaseOrders.purchasesAreLoading);
    const user = useAppSelector((state) => state?.partners?.userData)
    const state = useAppSelector((state) => state.purchaseOrders);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Purchase Orders')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10
        })
    }

    const onChangeFilterSelected = (value: ISelectedValue, type?: string) => {
        if (type) {
            switch (type) {
                case 'status':
                    setSelectedStatus(value)
                    break;
                case 'category':
                    setSelectedCategory(value)
                    break;
            }
            onResetPaginationState()
        }
    }

    useEffect(() => {
        if (state.purchases) {
            const rows = state.purchases?.data?.elements
            setRows(rows);
        }
    }, [state.purchases]);

    const onGettingFilters = () => {
        let data: any
        if (activeMarket) {
            data = {
                ...data || {},
                marketId: activeMarket?.market?._id,
            }
        }
        if (selectedCategory) {
            data = {
                ...data || {},
                assetTemplateId: selectedCategory?.id,
            }
        }
        if (selectedStatus) {
            data = {
                ...data || {},
                status: selectedStatus?.value,
            }
        }
        if (search) {
            data = {
                ...data || {},
                search: search,
            }
        }
        const formatData: any = { data: data }
        return formatData;
    }

    const onGettingPurchaseData = async () => {
        if (activeMarket) {
            try {
                const data = onGettingFilters()
                const fetchData = {
                    ...paginationState || {},
                    ...data || {}
                }
                await dispatch(tryToFetchPurchases(fetchData)).unwrap()
            } catch (err) {
                // err
            }
        }
    }

    useLayoutEffect(() => {
        if (query?.get('search')) {
            setSearch(query?.get('search') || undefined)
            onResetPaginationState()
        }
    }, [query?.get('search')])

    const getBrokerSectors = async () => {
        const response = user;
        const formatSectors: any = (response?.sectors?.length > 0) ? response?.sectors?.map((s: any) => ({ ...s, label: s?.displayName || s?.name, value: s?.name })) : [];
        setBrokerCategories(formatSectors || []);
    };

    useEffect(() => {
        if (user) {
            getBrokerSectors();
        }
    }, [user]);

    useEffect(() => {
        dispatch(tryToFetchUserData())
    }, [])

    useEffect(() => {
        if (activeMarket) {
            onGettingPurchaseData()
        }
    }, [paginationState, activeMarket])

    useEffect(() => {
        if (activeMarketState && ((JSON.stringify(activeMarketState) !== JSON.stringify(activeMarket)))) {
            setActiveMarket(activeMarketState || undefined)
            onResetPaginationState()
        }
    }, [activeMarketState])

    const onClickClearAllFilter = () => {
        setSelectedStatus(undefined)
        setSelectedCategory(undefined)
        setSearch(undefined);
        onResetPaginationState();
        searchParams.delete('search')
        setSearchParams(searchParams)
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        if (value) searchParams.set('search', value)
        else searchParams.delete('search')
        setSearchParams(searchParams)
        onResetPaginationState()
    }

    const onClickStatusChange = (id: string, type: string, data?: any) => {
        if (type) {
            switch (type) {
                case 'submit_draft':
                    document.body.style.overflow = 'hidden';
                    setShowSubmitModal(true);
                    setPurchaseId(id)
                    break;
                case 'edit_draft':
                    navigator(`/purchase-orders/edit/${id}`);
                    break;
                case 'create_copy':
                    navigator(`/purchase-orders/create/${id}?copy=true`);
                    break;
                case 'delete_draft':
                    document.body.style.overflow = 'hidden';
                    setShowDeleteModal(true)
                    setPurchaseId(id)
                    break;
            }
        }
    }

    const onClickShowHideSubmit = () => {
        setShowSubmitModal(false)
        setPurchaseId(undefined)
        document.body.style.overflow = 'auto';
    }

    const onSubmitPurchaseConfirm = async () => {
        try {
            setLoadingSubmit(true)
            await dispatch(tryToSubmitPurchase(purchaseId || '')).unwrap();
            setShowToast({ type: 'success', message: 'Successfully submitted' })
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
        }
        onClickShowHideSubmit()
        setLoadingSubmit(false)
    }

    const onClickShowHideDelete = () => {
        setShowDeleteModal(false)
        setPurchaseId(undefined)
        setLoadingDelete(false)
        document.body.style.overflow = 'auto';
    }

    const onDeletePurchaseConfirm = async () => {
        try {
            setLoadingDelete(true)
            await dispatch(tryToDeletePurchase(purchaseId || '')).unwrap();
            setShowToast({ type: 'success', message: 'Successfully deleted' })
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
        }
        onClickShowHideDelete()
        setLoadingDelete(false)
    }

    const onClickShowHideDetailsModal = (id?: string) => {
        if (id) {
            setShowDetailsModal(true)
            setPurchaseId(id)
            document.body.style.overflow = 'hidden';
        } else {
            setShowDetailsModal(false)
            setPurchaseId(undefined)
            document.body.style.overflow = 'auto';
        }
    }

    const onClickCreateOrder = () => {
        navigator('/purchase-orders/create')
    }

    const onClickDownloadOrderItems = async (items?: any) => {
        setItemsData(undefined)
        setLoadingDownload(true)
        try {
            const formatItems: any = [['Item Name', 'Price', 'Quantity', 'Currency']];
            (items && items?.length > 0) && items?.map((item: any) => {
                const itemName = [
                    ...item?.descriptor ? Object.entries(item?.descriptor).map(([key, value]) => `${value}`) : [],
                    ...item?.inventory ? Object.entries(item?.inventory).map(([key, value]) => key === 'sales_grade' ? `Grade ${value}` : `${value}`) : [],
                ].join(' ');

                const itemData = [itemName, item.price, item?.qty, item?.currency]
                formatItems.push(itemData);
                return item;
            })

            setItemsData(formatItems || [])
        } catch (err: any) {
            setShowToast({ type: 'error', message: `${err?.response || err?.data?.message || err}` })
            setLoadingDownload(false)
        }
    }

    useEffect(() => {
        if (itemsData && loadingDownload) {
            csvLink.current.link.click()
            setLoadingDownload(false)
        }
    }, [itemsData])

    const onClickShowImportModal = () => {
        if (showImportModal) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
        setShowImportModal(!showImportModal)
    }

    const onClickShowDownloadModal = () => {
        if (showDownloadModal) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
        setShowDownloadModal(!showDownloadModal)
    }

    const onClickSeeOffer = (item?: any) => {
        if (item) {
            document.body.style.overflow = 'hidden';
            setShowOfferModal({
                show: true,
                item
            })
        } else {
            document.body.style.overflow = 'auto';
            setShowOfferModal(undefined)
        }
    }

    const onSuccessfullyUploadOrder = () => {
        onGettingPurchaseData()
        setShowToast({ type: 'success', message: 'Successfully imported purchase order!' })
    }

    const onClickSeeAllCategories = (data?: any) => {
        if (data) {
            setShowCategoriesModal({
                show: true,
                data: data
            })
            document.body.style.overflow = 'hidden'
        } else {
            setShowCategoriesModal(undefined)
            document.body.style.overflow = 'auto'
        }
    }

    const onSeeOffersHistory = (id?: string, offers?: any) => {
        if (id) {
            setOffersHistoryModal({
                show: true,
                offers: offers
            })
            document.body.style.overflow = 'hidden'
        } else {
            setOffersHistoryModal(undefined)
            document.body.style.overflow = 'auto'
        }
    }

    return (
        <div>
            <div>
                <p className='page-title'>Purchase Orders</p>
            </div>
            <PurchaseFilters
                brokerCategories={brokerCategories}
                selectedCategory={selectedCategory}
                selectedStatus={selectedStatus}
                onClickFilter={onChangeFilterSelected}
            />
            <div className={`flex flex-row justify-between items-center bg-[#f8f0e6] mt-3 mb-2 p-1 !rounded`}>
                <div>
                    <Input
                        placeholder={'Filter Table'}
                        dataQa={'search'}
                        containerStyle='mb-0 pb-0 relative min-w-[400px]'
                        inputStyle={`mb-0 border-0 focus:outline-none`}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={search}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                    />
                </div>
                <div className='flex flex-row justify-end'>
                    <div className='flex flex-row justify-end ml-5'>
                        <Button
                            label='Clear Filters'
                            className={'btn-primary-text-underline min-w-auto'}
                            onClickButton={() => onClickClearAllFilter()}
                        />
                    </div>
                    <div className='col-span-2 flex flex-row justify-end mr-2'>
                        <Button
                            label={'Download Sample'}
                            onClickButton={() => (!accessControl || pageAccess?.actions?.['Download Sample']) && onClickShowDownloadModal()}
                            className={`${(!accessControl || pageAccess?.actions?.['Download Sample']) ? 'btn-primary' : 'btn-primary-disable'} !shadow-none`}
                        />
                    </div>
                    <div className='col-span-2 flex flex-row justify-end mr-2'>
                        <Button
                            label={'Import Order'}
                            onClickButton={() => (!accessControl || pageAccess?.actions?.['Import Order']) && onClickShowImportModal()}
                            className={`${(!accessControl || pageAccess?.actions?.['Import Order']) ? 'btn-primary' : 'btn-primary-disable'} !shadow-none`}
                        />
                    </div>
                    <div className='col-span-2 flex flex-row justify-end'>
                        <Button
                            label={'Create Order'}
                            onClickButton={() => (!accessControl || pageAccess?.actions?.['Create Purchase Order']) && onClickCreateOrder()}
                            className={`${(!accessControl || pageAccess?.actions?.['Create Purchase Order']) ? 'btn-primary' : 'btn-primary-disable'} !shadow-none`}
                        />
                    </div>
                </div>
            </div>
            <div>
                <PurchaseOrdersTable
                    rows={rows}
                    rowsLoading={purchaseLoading}
                    brokerCategories={brokerCategories}
                    paginationData={paginationData}
                    accessControl={accessControl}
                    actions={pageAccess?.actions}
                    onSeeOffersHistory={onSeeOffersHistory}
                    onClickSeeOffer={onClickSeeOffer}
                    setPaginationState={setPaginationState}
                    onClickStatusChange={onClickStatusChange}
                    onSeeDetails={onClickShowHideDetailsModal}
                    onClickDownloadOrder={onClickDownloadOrderItems}
                    onClickSeeAllCategories={onClickSeeAllCategories}
                />
            </div>
            <CSVLink
                ref={csvLink}
                data={(itemsData && itemsData?.length > 0) ? itemsData?.filter((item: any, idx: number) => idx > 0) || [] : []}
                headers={itemsData?.[0] || []}
                className={'none'}
                target={'_blank'}
                filename={`Purchase-items-${moment().format('dd-mm-yyyy')}.csv`}
            >
            </CSVLink>
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            {showSubmitModal &&
                <ConfirmSubmitModal
                    openModal={showSubmitModal}
                    loading={loadingSubmit}
                    handleCloseModal={onClickShowHideSubmit}
                    onApproveAction={onSubmitPurchaseConfirm}
                />
            }
            {showDeleteModal &&
                <ConfirmDeleteModal
                    openModal={showDeleteModal}
                    loading={loadingDelete}
                    handleCloseModal={onClickShowHideDelete}
                    onApproveAction={onDeletePurchaseConfirm}
                />
            }
            {showDetailsModal &&
                <PurchaseDetailsModal
                    openModal={showDetailsModal}
                    purchaseId={purchaseId || ''}
                    categories={brokerCategories}
                    handleCloseModal={onClickShowHideDetailsModal}
                />
            }
            {showDownloadModal &&
                <DownloadSampleModal
                    openDownloadModal={showDownloadModal}
                    sectors={brokerCategories}
                    handleCloseDownloadModal={onClickShowDownloadModal}
                />
            }
            {showImportModal &&
                <ImportOrderModal
                    openImportModal={showImportModal}
                    categories={brokerCategories}
                    handleCloseModal={onClickShowImportModal}
                    onSuccessfullyUploadItems={onSuccessfullyUploadOrder}
                />
            }
            {showOfferModal?.show &&
                <OfferModal
                    openModal={showOfferModal?.show}
                    data={showOfferModal?.item}
                    brokerCategories={brokerCategories}
                    itemId={showOfferModal?.item?._id}
                    handleCloseModal={onClickSeeOffer}
                />
            }
            {showCategoriesModal?.show &&
                <AllCategoriesModal
                    openModal={showCategoriesModal?.show}
                    categories={showCategoriesModal?.data}
                    handleCloseModal={onClickSeeAllCategories}
                />
            }
            {offersHistoryModal?.show &&
                <OffersHistoryModal
                    openModal={offersHistoryModal?.show}
                    offers={offersHistoryModal?.offers}
                    handleCloseModal={onSeeOffersHistory}
                />
            }
        </div>
    )
}
export default PurchaseOrders;