import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
    IPaginationPayload,
    IPaginationPayloadWithId,
} from '../../../../interfaces/shared/IPaginationPayload';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';

import { AssetsService } from './assetsApi';
import { IAssetsListState } from '../../../../interfaces/assets/IAssetsList';
import { IAssets } from '../../../../interfaces/assets/IAssets';
import { IAssetSchema } from '../../../../interfaces/assets/IAssetsSchema';
import { PaginatedAsset } from '../../../../interfaces/assets/paginatedStore.type';
import { IAssetStatusUpdate } from '../../../../interfaces/assets/IAssetStatusUpdate';

const initialState: IAssetsListState = {
    assetIsLoading: false,
    assetsAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchAssets = createAsyncThunk<
    PaginatedAsset,
    IPaginationPayloadWithId
>(
    'assets/tryToFetchAssets',
    async ({ pageNumber, pageSize, filters, data, id }) => {
        const result = await AssetsService.tryToFetchAssets(
            pageNumber,
            pageSize,
            id,
            filters,
            data
        );
        return result.data;
    },
);

export const tryToFetchSingleAsset = createAsyncThunk<IAssets, any>(
    'assets/tryToFetchSingleAsset',
    async ({ id, assetId }) => {
        const result = await AssetsService.tryToFetchSingleAsset(id, assetId);
        return result?.data?.data;
    },
);

export const tryFetchAssetFieldValuesDependent = createAsyncThunk<any, any>(
    'assets/tryFetchAssetFieldValuesDependent',
    async ({ id, data, replacements }) => {
        const result = await AssetsService.tryFetchAssetFieldValuesDependent(id, data, replacements);
        return result?.data?.data;
    },
);

export const tryFetchAssetFieldValues = createAsyncThunk<any, any>(
    'assets/tryFetchAssetFieldValues',
    async ({ id, field, search, replacements }) => {
        const result = await AssetsService.tryFetchAssetFieldValues(id, field, search, replacements);
        return result?.data?.data;
    },
);

export const tryToFetchAssetValues = createAsyncThunk<any, any>(
    'assets/tryToFetchAssetValues',
    async ({ id, data }) => {
        const result = await AssetsService.tryToFetchAssetValues(id, data);
        return result?.data?.data;
    },
);

export const tryToAddAsset = createAsyncThunk<
    ApiResponse<IAssetSchema>,
    IAssets
>(
    'assets/tryToAddAsset',
    async (data: IAssets, { rejectWithValue }) => {
        try {
            const result = await AssetsService.tryToAddAsset(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditAsset = createAsyncThunk<ApiResponse<IAssetSchema>, IAssets>(
    'assets/tryToEditAsset',
    async (data: IAssets, { rejectWithValue }) => {
        try {
            const result = await AssetsService.tryToEditAsset(
                data._id || '',
                data,
            );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToChangeAssetsStatus = createAsyncThunk<ApiResponse<any>, { assetTemplateId: string, data: IAssetStatusUpdate }>(
    'assets/tryToChangeAssetsStatus',
    async ({ assetTemplateId, data }, { rejectWithValue }) => {
        try {
            const result = await AssetsService.tryToChangeAssetsStatus(assetTemplateId, data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err);
        }
    },
);

export const tryToDeleteAssetCatalogData = createAsyncThunk<ApiResponse<any>, string>(
    'stores/tryToDeleteAssetCatalogData',
    async (assetTemplateId, { rejectWithValue }) => {
        try {
            const results = await AssetsService.tryToDeleteAssetCatalogData(assetTemplateId);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    });

export const tryToDisableAsset = createAsyncThunk<
    ApiResponse<IAssetSchema>,
    { assetTemplateId: string; assetId: string }
>('stores/tryToDisableAsset', async (data, { rejectWithValue }) => {
    try {
        const results = await AssetsService.tryToDisableAsset(
            data?.assetTemplateId,
            data?.assetId,
        );
        return results.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToImportAssetData = createAsyncThunk<IAssetSchema[], { assetTemplateId: string; assets: Array<any> }>(
    'assets/tryToImportAssetData',
    async (data: { assetTemplateId: string; assets: Array<any> }, { rejectWithValue }) => {
        try {
            const result = await AssetsService.tryToImportAssetData(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToImportAssetImagesData = createAsyncThunk<ApiResponse<IAssetSchema[]>, { assetTemplateId: string; data: any, rental?: boolean }>(
    'assets/tryToImportAssetImagesData',
    async (data: { assetTemplateId: string; data: any, rental?: boolean }, { rejectWithValue }) => {
        try {
            const result = await AssetsService.tryToImportAssetImagesData(data?.assetTemplateId, data?.data, data?.rental);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToImportExtendedAssetData = createAsyncThunk<ApiResponse<any>, { extendedAssets: Array<any> }>(
    'assets/tryToImportExtendedAssetData',
    async (data: { extendedAssets: Array<any> }, { rejectWithValue }) => {
        try {
            const result = await AssetsService.tryToImportExtendedAssetData(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchAssetCurrencies = createAsyncThunk(
    'assets/tryToFetchAssetCurrencies',
    async () => {
        const result = await AssetsService.tryToFetchAssetCurrencies();
        return result?.data;
    },
);

export const tryToDownloadExtendedAssetTemplate = createAsyncThunk(
    'assets/tryToDownloadExtendedAssetTemplate',
    async () => {
        const result = await AssetsService.tryToDownloadExtendedAssetTemplate();
        return result?.data;
    },
);

export const tryToDownloadExtendedAssetData = createAsyncThunk(
    'assets/tryToDownloadExtendedAssetData',
    async () => {
        const result = await AssetsService.tryToDownloadExtendedAssetData();
        return result?.data;
    },
);

export const tryToFetchExtendedAssets = createAsyncThunk<
    PaginatedAsset,
    IPaginationPayload
>('assets/tryToFetchExtendedAssets',
    async ({ pageNumber, pageSize, filters }) => {
        const result = await AssetsService.tryToFetchExtendedAssets(
            pageNumber,
            pageSize,
            filters,
        );
        return result.data;
    },
);

export const tryToAddExtendedAsset = createAsyncThunk<ApiResponse<any>, any>(
    'assets/tryToAddExtendedAsset',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await AssetsService.tryToAddExtendedAsset(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditExtendedAsset = createAsyncThunk<ApiResponse<any>, any>(
    'assets/tryToEditExtendedAsset',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await AssetsService.tryToEditExtendedAsset(id, data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchSingleAssetExtended = createAsyncThunk<any, string>(
    'assets/tryToFetchSingleAssetExtended',
    async (id) => {
        const result = await AssetsService.tryToFetchSingleAssetExtended(id);
        return result?.data;
    },
);

export const tryToDeleteExtendedAsset = createAsyncThunk<ApiResponse<any>, any>(
    'assets/tryToDeleteExtendedAsset',
    async (id, { rejectWithValue }) => {
        try {
            const results = await AssetsService.tryToDeleteExtendedAsset(id);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToSearchNotAddedExtendedAssets = createAsyncThunk<ApiResponse<any>, any>(
    'assets/tryToSearchNotAddedExtendedAssets',
    async (search, { rejectWithValue }) => {
        try {
            const results = await AssetsService.tryToSearchNotAddedExtendedAssets(search);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const assetsSlice = createSlice({
    name: 'assets',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchAssets
            .addCase(tryToFetchAssets.pending, (state) => {
                state.assetsAreLoading = true;
            })
            .addCase(tryToFetchAssets.rejected, (state) => {
                state.assetsAreLoading = false;
            })
            .addCase(tryToFetchAssets.fulfilled, (state, action) => {
                state.assetsAreLoading = false;
                state.assets = action.payload;
            })

            // tryToFetchSingleAsset
            .addCase(tryToFetchSingleAsset.pending, (state) => {
                state.assetIsLoading = true;
            })
            .addCase(tryToFetchSingleAsset.rejected, (state) => {
                state.assetIsLoading = false;
            })
            .addCase(tryToFetchSingleAsset.fulfilled, (state, action) => {
                state.assetIsLoading = false;
                state.assetsDetails = action.payload;
            })

            // tryToAddAsset
            .addCase(tryToAddAsset.pending, (state) => {
                state.assetIsLoading = true;
            })
            .addCase(tryToAddAsset.rejected, (state) => {
                state.assetIsLoading = false;
            })
            .addCase(tryToAddAsset.fulfilled, (state, action) => {
                state.assetIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.assets?.data)
                    state.assets.data.elements = [
                        ...state.assets?.data.elements,
                        data,
                    ];
            })

            // tryToImportAssetData
            .addCase(tryToImportAssetData.pending, (state) => {
                state.assetIsLoading = true;
            })
            .addCase(tryToImportAssetData.rejected, (state) => {
                state.assetIsLoading = false;
            })
            .addCase(tryToImportAssetData.fulfilled, (state, action) => {
                state.assetIsLoading = false;
                state.requestStatus = 'success';
                const data: any = action.payload || action.meta.arg;
                if (state.assets?.data)
                    state.assets.data.elements = [
                        ...(data || data?.data || []),
                        ...state.assets?.data.elements,
                    ];
            })

            // tryToEditAsset
            .addCase(tryToEditAsset.pending, (state) => {
                state.assetIsLoading = true;
            })
            .addCase(tryToEditAsset.rejected, (state) => {
                state.assetIsLoading = false;
            })
            .addCase(tryToEditAsset.fulfilled, (state, action) => {
                state.assetIsLoading = false;
                if (state.assets?.data.elements)
                    state.assets.data.elements =
                        { ...state }.assets?.data.elements.map((asset) => {
                            if (asset?._id === action.payload?.data?._id) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return asset;
                            }
                        }) || [];
                state.assetsDetails = action.payload.data;
            })

            // tryToChangeAssetsStatus
            .addCase(tryToChangeAssetsStatus.pending, (state) => {
                state.assetIsLoading = true;
            })
            .addCase(tryToChangeAssetsStatus.rejected, (state) => {
                state.assetIsLoading = false;
            })
            .addCase(tryToChangeAssetsStatus.fulfilled, (state, action) => {
                state.assetIsLoading = false;
                if (state.assets?.data?.elements)
                    state.assets.data.elements =
                        { ...state }.assets?.data.elements.map((asset) => {
                            if (asset?._id && action.meta.arg.data?.assets?.includes(asset?._id || '')) {
                                return {
                                    ...asset || {},
                                    status: action?.meta?.arg?.data?.status
                                };
                            } else {
                                return asset;
                            }
                        }) || [];
                state.assetsDetails = action.payload.data;
            })

            // tryToDisableAsset
            .addCase(tryToDisableAsset.pending, (state) => {
                state.assetIsLoading = true;
            })
            .addCase(tryToDisableAsset.rejected, (state) => {
                state.assetIsLoading = false;
            })
            .addCase(tryToDisableAsset.fulfilled, (state, action) => {
                state.assetIsLoading = false;
                state.requestStatus = 'success';
                if (state.assets?.data.elements)
                    state.assets.data.elements =
                        { ...state }.assets?.data.elements.filter(
                            (asset) => asset?._id !== action.meta.arg?.assetId,
                        ) || [];
            })

            // tryToFetchExtendedAssets
            .addCase(tryToFetchExtendedAssets.pending, (state) => {
                state.assetsAreLoading = true;
            })
            .addCase(tryToFetchExtendedAssets.rejected, (state) => {
                state.assetsAreLoading = false;
            })
            .addCase(tryToFetchExtendedAssets.fulfilled, (state, action) => {
                state.assetsAreLoading = false;
                state.extendedAssets = action.payload;
            })

            // tryToAddExtendedAsset
            .addCase(tryToAddExtendedAsset.pending, (state) => {
                state.assetIsLoading = true;
            })
            .addCase(tryToAddExtendedAsset.rejected, (state) => {
                state.assetIsLoading = false;
            })
            .addCase(tryToAddExtendedAsset.fulfilled, (state, action) => {
                state.assetIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.extendedAssets?.data) {
                    state.extendedAssets.data.elements = [
                        data,
                        ...state.extendedAssets?.data.elements,
                    ];
                }
                if (state.assets?.data?.elements) {
                    state.assets.data.elements =
                        { ...state }.assets?.data.elements.map((asset) => {
                            if (asset?.descriptor?.model && action?.payload?.data?.name) {
                                return {
                                    ...asset || {},
                                    extendedAsset: true
                                };
                            } else {
                                return asset;
                            }
                        }) || [];
                }
            })

            // tryToEditExtendedAsset
            .addCase(tryToEditExtendedAsset.pending, (state) => {
                state.assetIsLoading = true;
            })
            .addCase(tryToEditExtendedAsset.rejected, (state) => {
                state.assetIsLoading = false;
            })
            .addCase(tryToEditExtendedAsset.fulfilled, (state, action) => {
                state.assetIsLoading = false;
                if (state.extendedAssets?.data.elements)
                    state.extendedAssets.data.elements =
                        { ...state }.extendedAssets?.data.elements.map((asset) => {
                            if (asset?._id === action.payload?.data?._id) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return asset;
                            }
                        }) || [];
                state.extendedAsset = action.payload.data;
            })

            // tryToFetchSingleAssetExtended
            .addCase(tryToFetchSingleAssetExtended.pending, (state) => {
                state.assetIsLoading = true;
            })
            .addCase(tryToFetchSingleAssetExtended.rejected, (state) => {
                state.assetIsLoading = false;
            })
            .addCase(tryToFetchSingleAssetExtended.fulfilled, (state, action) => {
                state.assetIsLoading = false;
                state.extendedAsset = action.payload;
            })

            // tryToDeleteExtendedAsset
            .addCase(tryToDeleteExtendedAsset.pending, (state) => {
                state.assetIsLoading = true;
            })
            .addCase(tryToDeleteExtendedAsset.rejected, (state) => {
                state.assetIsLoading = false;
            })
            .addCase(tryToDeleteExtendedAsset.fulfilled, (state, action) => {
                state.assetIsLoading = false;
                state.requestStatus = 'success';
                if (state.extendedAssets?.data.elements)
                    state.extendedAssets.data.elements =
                        { ...state }.extendedAssets?.data.elements.filter((asset) => asset?._id !== action?.meta?.arg) || [];
            })

            // tryToFetchAssetCurrencies
            .addCase(tryToFetchAssetCurrencies.pending, (state) => {
                state.assetsAreLoading = true;
            })
            .addCase(tryToFetchAssetCurrencies.rejected, (state) => {
                state.assetsAreLoading = false;
            })
            .addCase(tryToFetchAssetCurrencies.fulfilled, (state, action) => {
                state.assetsAreLoading = false;
                state.requestStatus = 'success';
                state.currencies = action.payload;
            });
    },
});

export default assetsSlice.reducer;
