import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { CURRENCY_SYMBOLS } from '../../../utils/constants/currency-symbols';
import { PURCHASE_ORDER_STATUS } from '../../../utils/constants/purchase-order-status';
import { validatePurchaseFields } from '../../../utils/constants/purchase-validation-fields';
import {
    tryToCreatePurchase,
    tryToEditPurchase,
    tryToFetchSinglePurchase,
    tryToSubmitPurchase,
} from '../../../store/brokers/partners/purchase-orders/purchaseOrdersSlice';
import { tryToFetchAssetValues } from '../../../store/brokers/admin/assets/assetsSlice';
import { tryToFetchUserData } from '../../../store/brokers/admin/partners/partnersSlice';
import { tryToFetchAssetTemplateFieldsFilter } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import CategoryStep from './form-steps/CategoryStep';
import FieldsStep from './form-steps/FieldsStep';
import ItemsStep from './form-steps/ItemsStep';
import ImportPurchaseItemsModal from './modals/ImportPurchaseItemsModal';
import ConfirmPurchaseCreate from './modals/ConfirmPurchaseCreate';
import Button from '../../../shared/button';
import Error from '../../../shared/error';

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PurchaseForm = () => {
    const { id } = useParams();
    const navigator = useNavigate();
    const dispatch = useAppDispatch();
    const query = useQuery();
    const [brokerCategories, setBrokerCategories] = useState<Array<any>>();
    const [selectedCategory, setSelectedCategory] = useState<any>();
    const [templateFields, setTemplateFields] = useState<any>();
    const [templateData, setTemplateData] = useState<any>();
    const [allRowsItems, setAllRowsItems] = useState<any>([{}]);
    const [allRowsData, setAllRowsData] = useState<any>([{}]);
    const [itemsErrors, setItemsErrors] = useState<any>();
    const [errorStep, setErrorStep] = useState<string>();
    const [fieldsData, setFieldsData] = useState<any>();
    const [itemRowChanged, setItemRowChanged] = useState<number>();
    const [itemCategoryChanged, setItemCategoryChanged] = useState<any>()
    const [itemsLoading, setItemsLoading] = useState<{ loading: boolean; row: number; }>();
    const [formFieldsData, setFormFieldsData] = useState<any>();
    const [formFieldsErrors, setFormFieldsErrors] = useState<any>();
    const [searchInDB, setSearchInDB] = useState<any>();
    const [inventoryFields, setInventoryFields] = useState<any>();
    const [loadingValues, setLoadingValues] = useState<any>();
    const [searchField, setSearchField] = useState<any>();
    const [loadingFields, setLoadingFields] = useState<boolean>();
    const [loadingOptions, setLoadingOptions] = useState<any>();
    const [filteredFields, setFilteredFields] = useState<any>();
    const [itemsGeneralError, setItemsGeneralError] = useState<string>();
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [isCopy, setIsCopy] = useState<boolean>(false);
    const [pageAccess, setPageAccess] = useState<any>();
    const [showImportItemsModal, setShowImportItemsModal] = useState<{ show: boolean, category: any }>();
    const activeMarket = useAppSelector((state) => state?.partners?.partnerActiveMarket);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const user = useAppSelector((state) => state.partners?.userData);
    const state = useAppSelector((state) => state.purchaseOrders);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Purchase Orders')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const getBrokerSectors = async () => {
        const response = user;
        const formatSectors: any = response?.sectors?.length > 0 ? response?.sectors?.map((s: any) => ({ ...s, label: s?.displayName || s?.name, value: s?.id, })) : [];
        setBrokerCategories(formatSectors || []);
    };

    const getPurchaseDetailsById = async () => {
        await dispatch(tryToFetchSinglePurchase(id || ''));
    };

    useEffect(() => {
        if (state.purchaseDetails && id) {
            setFormFieldsData({
                ...state?.purchaseDetails?.purchaseInfo || {},
                dueDate: `${moment(state?.purchaseDetails?.purchaseInfo?.dueDate).format('YYYY-MM-DD')}`
            });
            setAllRowsData(state?.purchaseDetails?.expectedItems || undefined);
            setAllRowsItems(state?.purchaseDetails?.expectedItems || undefined);
        } else {
            setFormFieldsData(undefined);
            setAllRowsData(undefined);
            setAllRowsItems(undefined);
            setSelectedCategory(undefined);
        }
    }, [state.purchaseDetails]);

    useEffect(() => {
        if (state.purchaseDetails && id && brokerCategories) {
            const formatCategories = (state?.purchaseDetails?.categories && state?.purchaseDetails?.categories?.length > 0) &&
                state?.purchaseDetails?.categories?.map((category: any) => {
                    const findCategory = brokerCategories && brokerCategories?.length > 0 && brokerCategories?.find((item: any) => item?.value === category?.id)
                    return findCategory
                })
            setSelectedCategory(formatCategories || undefined);
        } else {
            setSelectedCategory(undefined);
        }
    }, [state.purchaseDetails, brokerCategories]);

    useEffect(() => {
        if (query?.get('copy')) {
            setIsCopy(true)
        } else {
            setIsCopy(false)
        }
    }, [query?.get('copy')])

    useEffect(() => {
        if (id) {
            getPurchaseDetailsById();
            if (!user) {
                dispatch(tryToFetchUserData())
            }
        } else {
            setSelectedCategory(undefined);
            setAllRowsData(undefined);
            setAllRowsItems(undefined);
            setFormFieldsData(undefined);
        }
    }, [id]);

    useEffect(() => {
        if (user) {
            getBrokerSectors();
        } else {
            dispatch(tryToFetchUserData())
        }
        const getUser = user?.broker || user?.username;
        setFormFieldsData((prevFormData: any) => ({
            ...(prevFormData || {}),
            orderCreator: getUser,
            status: PURCHASE_ORDER_STATUS?.find((item: any) => (item?.name).toLowerCase() === 'draft')?.value,
        }));
    }, [user])

    useEffect(() => {
        if (!user) {
            dispatch(tryToFetchUserData())
        }
        getBrokerSectors();
        setFieldsData((prevFieldsData: any) => ({
            ...(prevFieldsData || {}),
            status: PURCHASE_ORDER_STATUS?.find((item: any) => (item?.name).toLowerCase() === 'draft'),
        }));
        localStorage.setItem('prev', 'purchases-create')
    }, []);

    const onClickCategory = (value: any) => {
        const findCategory = (selectedCategory && selectedCategory?.length > 0) && selectedCategory?.find((item: any) => item?.value === value?.value)
        if (findCategory) {
            const filterCategories = (selectedCategory && selectedCategory?.length > 0) && selectedCategory?.filter((item: any) => item?.value !== value?.value)
            setSelectedCategory(filterCategories || [])
        } else {
            setSelectedCategory([
                ...selectedCategory || [],
                value
            ]);
        }
    };

    const onClickAddItems = (category: any) => {
        const findTemplateFields = templateFields?.[category?.name]
        setAllRowsItems([...(allRowsItems || []), { ...findTemplateFields || {}, categoryId: category?.value }]);
        setAllRowsData([
            ...(allRowsData || []),
            {
                categoryId: category?.value
                // index: allRowsItems?.length || 0
            },
        ]);
    };

    const areObjectsEqual = (obj1: any, obj2: any) => {
        const stringifiedObj1 = obj1 ? JSON.stringify(obj1, Object.keys(obj1).sort()) : null
        const stringifiedObj2 = obj2 ? JSON.stringify(obj2, Object.keys(obj2).sort()) : null
        return stringifiedObj1 === stringifiedObj2;
    }

    const onDeleteItemRow = (id: number, categoryId: string) => {
        if (id === 0 && allRowsItems?.length === 1) {
            setAllRowsData(undefined);
            setItemsErrors(undefined);
            setSearchField(undefined);
            setSearchInDB(undefined);
            setLoadingOptions(undefined);
            setFilteredFields(undefined);
            setItemRowChanged(0);
            setItemCategoryChanged(undefined);
            return;
        }
        const filterItemsPerCategory = (allRowsItems && allRowsItems?.length > 0) ? allRowsItems?.filter((item: any, idx: number) => item?.categoryId === categoryId) : [];
        const findItemPerCategory = (filterItemsPerCategory && filterItemsPerCategory?.length > 0) ? filterItemsPerCategory?.find((item: any, idx: number) => idx === id) : [];
        const allRowItemsData = (allRowsItems && allRowsItems?.length > 0) ? allRowsItems?.filter((item: any, idx: number) => !((areObjectsEqual(item, findItemPerCategory)) && item?.categoryId === categoryId)) : [];
        const filterDataPerCategory = (allRowsData && allRowsData?.length > 0) ? allRowsData?.filter((item: any, idx: number) => item?.categoryId === categoryId) : [];
        const findDataPerCategory = (filterDataPerCategory && filterDataPerCategory?.length > 0) ? filterDataPerCategory?.find((item: any, idx: number) => idx === id) : [];
        const allRowsDataFilter = (allRowsData && allRowsData?.length > 0) ? allRowsData?.filter((item: any, idx: number) => !((areObjectsEqual(item, findDataPerCategory)) && item?.categoryId === categoryId)) : [];
        const filterItemErrors = itemsErrors && itemsErrors?.length > 0 && itemsErrors?.filter((item: any) => (item?.index !== id) && (item?.categoryId !== categoryId));
        setAllRowsData(allRowsDataFilter || []);
        setItemsErrors(filterItemErrors || undefined);
        setAllRowsItems(allRowItemsData || []);
    };

    const onCloneRow = (id: number, categoryId: string) => {
        const filterItemsRows = (allRowsItems && allRowsItems?.length > 0) ? allRowsItems?.filter((item: any, idx: number) => item?.categoryId === categoryId) : [];
        const cloneRow = filterItemsRows?.[id];
        const filterDataRows = (allRowsData && allRowsData?.length > 0) ? allRowsData?.filter((item: any, idx: number) => item?.categoryId === categoryId) : [];
        const cloneData = filterDataRows && filterDataRows?.length > 0 && filterDataRows?.find((row: any, idx: number) => idx === id);
        const formatAllItems = allRowsItems ? [...(allRowsData || []), cloneRow || {}] : [{}, cloneData || {}];
        setAllRowsItems(formatAllItems);
        setAllRowsData((prevAllRowsData: any) => [
            ...(prevAllRowsData || []),
            {
                ...(cloneData || {}),
                price: undefined,
                qty: undefined,
                // index: allRowsItems?.length || 1
            },
        ]);
    };

    const onChangeSelectedValueTable = (value: any, type?: string, index?: any, parent?: any) => {
        if (((type && index) || (index === 0 && type)) && (parent && parent?.value)) {
            setItemsLoading({ loading: true, row: index });
            const filterCategoryData = (allRowsData && allRowsData?.length > 0) && allRowsData?.filter((item: any) => item?.categoryId === parent?.value)
            const findItem = (filterCategoryData && filterCategoryData?.length > 0) && filterCategoryData?.find((item: any, idx: number) => idx === index)
            let indexFormat: number
            const dataFormat = filterCategoryData && filterCategoryData?.length > 0 ?
                (allRowsData && allRowsData?.length > 0) ?
                    allRowsData?.map((row: any, idx: number) => {
                        if (row?.categoryId === parent?.value) {
                            if (indexFormat === 0 || indexFormat > 0) {
                                indexFormat = indexFormat + 1
                            } else {
                                indexFormat = 0
                            }
                        }
                        if (areObjectsEqual(findItem, row) && (indexFormat === index)) {
                            return {
                                ...(row || {}),
                                categoryId: parent?.value,
                                descriptor: {
                                    ...(row?.descriptor || {}),
                                    [type]: value?.value || undefined,
                                },
                            };
                        } else {
                            return row;
                        }
                    })
                    : [{ descriptor: { [type]: value?.value || undefined }, categoryId: parent?.value }]
                : [...allRowsData || [], { descriptor: { [type]: value?.value || undefined }, categoryId: parent?.value }]
            setAllRowsData(dataFormat || []);
            setItemRowChanged(index);
            setItemCategoryChanged(parent);
        }
    };

    const onChangeInputValueTable = (value: any, type?: string, index?: any, parent?: any) => {
        if (((type && index) || (index === 0 && type)) && (parent && parent?.value)) {
            const filterCategoryData = (allRowsData && allRowsData?.length > 0) && allRowsData?.filter((item: any) => item?.categoryId === parent?.value)
            const rowsDataFormat = (filterCategoryData && filterCategoryData?.length > 0) && filterCategoryData?.find((item: any, idx: number) => idx === index)
            let indexFormat: number
            const dataFormat = filterCategoryData && filterCategoryData?.length > 0 ?
                (allRowsData && allRowsData?.length > 0)
                    ? allRowsData?.map((row: any, idx: number) => {
                        if (row?.categoryId === parent?.value) {
                            if (indexFormat === 0 || indexFormat > 0) {
                                indexFormat = indexFormat + 1
                            } else {
                                indexFormat = 0
                            }
                        }
                        if (areObjectsEqual(rowsDataFormat, row) && (indexFormat === index)) {
                            return {
                                ...(row || {}),
                                categoryId: parent?.value,
                                [type]: type === 'price' ? /^\d*\.?\d*$/.test(value) ? value : rowsDataFormat?.[type] : value || undefined,
                            };
                        } else {
                            return row;
                        }
                    })
                    : [
                        {
                            [type]: type === 'price' ? /^\d*\.?\d*$/.test(value) ? value : rowsDataFormat?.[type] : value || undefined,
                            categoryId: parent?.value,
                        },
                    ]
                : [
                    ...allRowsData || [],
                    {
                        [type]: type === 'price' ? /^\d*\.?\d*$/.test(value) ? value : rowsDataFormat?.[type] : value || undefined,
                        categoryId: parent?.value,
                    }
                ]
            setAllRowsData(dataFormat || []);
        }
    };

    const onChangeInput = (value: string | null | undefined, type: string) => {
        setFormFieldsData({
            ...(formFieldsData || {}),
            [type]: value,
        });
    };

    const onChangeSelectedValue = (e: any, type?: string) => {
        if (type) {
            setFormFieldsData({
                ...(formFieldsData || {}),
                [type]: e.value || e.value === false ? e.value : undefined,
            });
            setFieldsData({
                ...(fieldsData || {}),
                [type]: e || undefined,
            });
        }
    };

    const onChangeDateInput = (e: any, type?: string) => {
        if (type) {
            setFormFieldsData({
                ...(formFieldsData || {}),
                [type]: e || undefined,
            });
        }
    };

    const onMenuCloseAfterSearch = (type?: string, index?: any) => {
        if (type) {
            setSearchField(undefined);
            setSearchInDB(undefined);
        }
    };

    const onClickSearchInDb = (type?: any, index?: any, parent?: any) => {
        if (searchInDB?.[type] && (index || index === 0)) {
            setFilteredFields(undefined);
            setLoadingOptions({ index, [type]: true });
            setSearchField({
                search: {
                    [type]: searchInDB?.searchValue,
                },
                index: index,
                category: parent
            });
            const findAllRowsCategory = allRowsData?.length > 0 && allRowsData?.filter((item: any, idx: number) => item?.categoryId === parent?.value);
            const findAllRowsData = findAllRowsCategory?.length > 0 && findAllRowsCategory?.find((item: any, idx: number) => idx === index);
            if (findAllRowsData && findAllRowsData?.[type]) {
                const formatFormData = Object.assign(findAllRowsData);
                delete formatFormData[type];
                setAllRowsData(
                    (prevRowsData: any) =>
                        prevRowsData?.map((item: any, idx: number) => {
                            if (areObjectsEqual(findAllRowsData, item)) {
                                return {
                                    ...(formatFormData || {}),
                                };
                            } else {
                                return item;
                            }
                        }) || []
                );
            }
        }
    };

    const onChangeSearchAssetFields = (e: any, type?: string, index?: number, parent?: any) => {
        if (type && e && (index || index === 0) && (parent && parent?.name)) {
            if (templateData && templateData?.[parent?.name]?.[type]) {
                const formatFilteredFields = filteredFields?.[parent?.name]?.[index] ? filteredFields?.[parent?.name]?.[index]?.[type]?.filter((next: any) => (next || '').toLowerCase().includes((e || '')?.toLowerCase())) : (templateData?.[parent?.name]?.[type] && templateData?.[parent?.name]?.[type]?.length > 0) && templateData?.[parent?.name]?.[type]?.filter((next: any) => (next || '').toLowerCase().includes((e || '')?.toLowerCase()))
                if (formatFilteredFields && formatFilteredFields?.length > 0) {
                    setSearchInDB({ [type]: true, searchValue: e, index, category: parent });
                    setFilteredFields((prevFilteredFields: any) => ({
                        ...(prevFilteredFields || {}),
                        [parent?.name]: {
                            ...prevFilteredFields?.[parent?.name] || {},
                            [index]: {
                                ...(prevFilteredFields?.[index] || {}),
                                [type]: formatFilteredFields,
                            }
                        }
                    }));
                } else {
                    setFilteredFields((prevFilteredFields: any) => ({
                        ...(prevFilteredFields || {}),
                        [parent?.name]: {
                            ...prevFilteredFields?.[parent?.name] || {},
                            [index]: {
                                ...(prevFilteredFields?.[index] || {}),
                                [type]: undefined,
                            }
                        },
                    }));
                    setLoadingOptions({ [type]: true, index });
                    setSearchField({
                        index,
                        search: {
                            [type]: e,
                        },
                        category: parent
                    });
                }
            }
        }
    };

    const onChangeSelectedInventoryValue = (value: any, type?: string, index?: any, parent?: any) => {
        if (((type && index) || (index === 0 && type)) && (parent && parent?.name)) {
            const findField = inventoryFields?.[parent?.name] && inventoryFields?.[parent?.name]?.length > 0 && inventoryFields?.[parent?.name]?.find((field: any) => field?.name === type);
            const filterCategoryData = (allRowsData && allRowsData?.length > 0) && allRowsData?.filter((item: any) => item?.categoryId === parent?.value)
            const findItem = (filterCategoryData && filterCategoryData?.length > 0) && filterCategoryData?.find((item: any, idx: number) => idx === index)
            let indexFormat: number
            const dataFormat = filterCategoryData && filterCategoryData?.length > 0 ?
                (allRowsData && allRowsData?.length > 0)
                    ? allRowsData?.map((row: any, idx: number) => {
                        if (row?.categoryId === parent?.value) {
                            if (indexFormat === 0 || indexFormat > 0) {
                                indexFormat = indexFormat + 1
                            } else {
                                indexFormat = 0
                            }
                        }
                        if (areObjectsEqual(findItem, row) && (indexFormat === index)) {
                            return {
                                ...(row || {}),
                                categoryId: parent?.value,
                                inventory: {
                                    ...(row?.inventory || {}),
                                    [type]:
                                        findField?.dataType === 'boolean'
                                            ? value?.value === 'true'
                                            : value?.value || undefined,
                                },
                            };
                        } else {
                            return row;
                        }
                    })
                    : [{ inventory: { [type]: value?.value || undefined }, categoryId: parent?.value }]
                : [...allRowsData || [], { inventory: { [type]: value?.value || undefined }, categoryId: parent?.value }]
            setAllRowsData(dataFormat || []);
            setItemRowChanged(undefined);
            setItemCategoryChanged(undefined)
        }
    };

    const onChangeFilterInventoryInput = (value: string | null | undefined, type?: string, index?: any, parent?: any) => {
        if (((type && index) || (index === 0 && type)) && (parent && parent?.value)) {
            const findField = inventoryFields?.[parent?.name] && inventoryFields?.[parent?.name]?.length > 0 && inventoryFields?.[parent?.name]?.find((field: any) => field?.name === type);
            const filterCategoryData = (allRowsData && allRowsData?.length > 0) && allRowsData?.filter((item: any) => item?.categoryId === parent?.value)
            const findItem = (filterCategoryData && filterCategoryData?.length > 0) && filterCategoryData?.find((item: any, idx: number) => idx === index)
            let indexFormat: number
            const dataFormat = filterCategoryData && filterCategoryData?.length > 0 ?
                (allRowsData && allRowsData?.length > 0)
                    ? allRowsData?.map((row: any, idx: number) => {
                        if (row?.categoryId === parent?.value) {
                            if (indexFormat === 0 || indexFormat > 0) {
                                indexFormat = indexFormat + 1
                            } else {
                                indexFormat = 0
                            }
                        }
                        if (areObjectsEqual(findItem, row) && (indexFormat === index)) {
                            return {
                                ...(row || {}),
                                categoryId: parent?.value,
                                inventory: {
                                    ...(row?.inventory || {}),
                                    [type]: findField?.dataType === 'number' ? Number(value || 0) : value,
                                },
                            };
                        } else {
                            return row;
                        }
                    })
                    : [{ inventory: { [type]: findField?.dataType === 'number' ? Number(value || 0) : value || undefined }, categoryId: parent?.value }]
                : [...allRowsData || [], { inventory: { [type]: findField?.dataType === 'number' ? Number(value || 0) : value || undefined }, categoryId: parent?.value }]
            setAllRowsData(dataFormat || []);
            setItemRowChanged(undefined);
            setItemCategoryChanged(undefined);
        }
    };

    const onValidateItemsStep = () => {
        const rowErrors: any = [];
        if (allRowsData && allRowsData?.length > 0) {
            allRowsData?.map((item: any, idx: number) => {
                const findCategory = brokerCategories && brokerCategories?.length > 0 && brokerCategories?.find((category: any) => category?.value === item?.categoryId)
                const filterItemsForCategory = allRowsData && allRowsData?.length > 0 && allRowsData?.filter((row: any) => row?.categoryId === item?.categoryId)
                const findItemIndexInCategory = filterItemsForCategory && filterItemsForCategory?.length > 0 && filterItemsForCategory?.findIndex((row: any) => areObjectsEqual(row, item))
                const findKeys = findCategory && templateFields?.[findCategory?.name];
                const findInventoryKeys = findCategory && inventoryFields?.[findCategory?.name];
                if (findKeys && findKeys?.length > 0) {
                    let itemErrors: any;
                    if (!item.price) {
                        itemErrors = {
                            ...(itemErrors || {}),
                            price: 'Price is required',
                        };
                    }
                    if (!item.qty) {
                        itemErrors = {
                            ...(itemErrors || {}),
                            qty: 'Quantity is required',
                        };
                    }
                    findKeys?.map((key: any) => {
                        if (!item?.descriptor?.[key?.name] && key?.required) {
                            itemErrors = {
                                ...(itemErrors || {}),
                                [key?.name]: `${key?.name} shouldn't be empty`,
                            };
                        }
                        return key;
                    });
                    findInventoryKeys?.map((key: any) => {
                        if (!item?.inventory?.[key?.name] && key?.required) {
                            itemErrors = {
                                ...(itemErrors || {}),
                                [key?.name]: `${key?.name} shouldn't be empty`,
                            };
                        }
                        return key;
                    });
                    if (itemErrors) {
                        rowErrors.push({ ...itemErrors, index: findItemIndexInCategory || idx, categoryId: findCategory?.value });
                    }
                }
                return item;
            });
        } else if (!allRowsData || allRowsItems?.length !== allRowsData?.length) {
            setItemsGeneralError('Please fill form to continue on the next step.');
            return false;
        }
        if (rowErrors && rowErrors?.length > 0) {
            setItemsErrors(rowErrors || undefined);
            setItemsGeneralError(undefined);
            return false;
        } else {
            setItemsErrors(undefined);
            setItemsGeneralError(undefined);
            return true;
        }
    };

    const onGettingAllAssetFields = async () => {
        if (selectedCategory && selectedCategory?.length > 0) {
            try {
                await Promise.all(selectedCategory && selectedCategory?.length > 0 && selectedCategory?.map(async (item: any) => {
                    if (!templateFields?.[item?.name]) {
                        const response: any = await dispatch(tryToFetchAssetTemplateFieldsFilter(item?.value)).unwrap();
                        if (response?.fields) {
                            setTemplateFields((prevTemplateFields: any) => ({
                                ...prevTemplateFields || {},
                                [item?.name || '']: response?.fields
                            }));
                            setInventoryFields((prevInventoryFields: any) => ({
                                ...prevInventoryFields || {},
                                [item?.name || '']: response?.inventoryFields
                            }));
                        }
                    }
                    return item;
                }))
            } catch (err) {
                // error here
            }
            setLoadingValues(false);
        }
    };

    const onGettingFieldValues = async (index: number = 0) => {
        if (selectedCategory && templateFields) {
            if (itemRowChanged || itemRowChanged === 0 || searchField?.index || searchField?.index === 0) {
                setLoadingOptions({
                    index: (itemRowChanged || itemRowChanged === 0) ? itemRowChanged : searchField?.index,
                    category: itemCategoryChanged?.value || searchField?.category?.value
                });
            }
            let data: any = {
                values: [],
                inventory: true
            };
            if (allRowsData && allRowsData?.length > 0 && ((itemRowChanged || itemRowChanged === 0) || (searchField?.index || searchField?.index === 0))) {
                const filterAllRowsData = allRowsData?.length > 0 && allRowsData?.filter((item: any) => itemCategoryChanged ? item?.categoryId === itemCategoryChanged?.value : item?.categoryId === searchField?.category?.value)
                const findAllRowsData = filterAllRowsData && filterAllRowsData?.length > 0 && filterAllRowsData?.find((item: any, index: number) => ((itemRowChanged || itemRowChanged === 0 ? itemRowChanged : searchField?.index) === index));
                const keysArray = findAllRowsData?.descriptor && Object.keys(findAllRowsData?.descriptor);
                const formatValues = (keysArray && keysArray?.length > 0) && keysArray?.map((name: string) => { return { [name]: findAllRowsData?.descriptor?.[name] === 'Any' ? undefined : findAllRowsData?.descriptor?.[name] }; });
                if (formatValues) {
                    data = {
                        ...data || {},
                        values: formatValues || [],
                    };
                }
            }
            if (searchField && searchField?.search) {
                data = {
                    ...(data || {}),
                    search: searchField?.search || {},
                };
            }
            if ((itemRowChanged || itemRowChanged === 0) || (searchField?.index || searchField?.index === 0)) {
                const filterAllRowsData = allRowsData?.length > 0 && allRowsData?.filter((item: any) => itemCategoryChanged ? itemCategoryChanged?.value === item?.categoryId : item?.categoryId === searchField?.category?.value)
                const findAllRowsData = (filterAllRowsData && filterAllRowsData?.length > 0) && filterAllRowsData?.find((item: any, index: number) => ((itemRowChanged || itemRowChanged === 0 ? itemRowChanged : searchField?.index) === index));
                const response: any = await dispatch(tryToFetchAssetValues({ id: itemCategoryChanged?.value || searchField?.category?.value || findAllRowsData?.categoryId || '', data })).unwrap();
                const findCategory = (selectedCategory && selectedCategory?.length > 0) && selectedCategory?.find((item: any) => item?.value === findAllRowsData?.categoryId)
                const categoryFields = templateFields?.[itemCategoryChanged?.name || searchField?.category?.name || findCategory?.name || '']
                const formatResponse: any = response;
                if (categoryFields && categoryFields?.length > 0 && response) {
                    Object?.keys(response || {})?.forEach((item: string) => {
                        const findCategoryField = categoryFields?.find((category: any) => category?.name === item)
                        if (findCategoryField?.details && findCategoryField?.details?.length > 0) {
                            const fieldValueToId: any = {};
                            findCategoryField?.details?.forEach((obj: any) => {
                                fieldValueToId[obj?.fieldValue || ''] = parseInt(obj?.id);
                            });

                            const sortFieldValues = [...response?.[item]].sort((a: any, b: any) => {
                                const idA = fieldValueToId[a];
                                const idB = fieldValueToId[b];

                                if (idA === undefined) return 1;
                                if (idB === undefined) return -1;

                                return idA - idB;
                            })
                            formatResponse[item] = sortFieldValues
                        } else {
                            formatResponse[item] = response?.[item]
                        }
                    }
                    )
                }
                setFilteredFields((prevFilteredFields: any) => ({
                    ...(prevFilteredFields || {}),
                    [itemCategoryChanged?.name || searchField?.category?.name || findCategory?.name || '']: {
                        ...prevFilteredFields?.[findCategory?.name] || {},
                        [(itemRowChanged || itemRowChanged === 0)
                            ? itemRowChanged
                            : searchField?.index]: {
                            ...(formatResponse || {}),
                        }
                    }
                }));
            }
            else {
                await Promise.all(selectedCategory && selectedCategory?.length > 0 && selectedCategory?.map(async (item: any) => {
                    const response: any = await dispatch(tryToFetchAssetValues({ id: item?.value || '', data })).unwrap();
                    const categoryFields = templateFields?.[item?.name || '']
                    const formatResponse: any = response
                    if (categoryFields && categoryFields?.length > 0 && response) {
                        Object?.keys(response || {})?.forEach((responseItem: string) => {
                            const findCategoryField = categoryFields?.find((category: any) => category?.name === responseItem)
                            if (findCategoryField?.details && findCategoryField?.details?.length > 0) {
                                const fieldValueToId: any = {};
                                findCategoryField?.details?.forEach((obj: any) => {
                                    fieldValueToId[obj?.fieldValue || ''] = parseInt(obj?.id);
                                });
                                const sortFieldValues = [...response?.[responseItem]].sort((a: any, b: any) => {
                                    const idA = fieldValueToId[a];
                                    const idB = fieldValueToId[b];
                                    if (idA === undefined) return 1;
                                    if (idB === undefined) return -1;
                                    return idA - idB;
                                })
                                formatResponse[responseItem] = sortFieldValues
                            } else {
                                formatResponse[responseItem] = response?.[responseItem]
                            }
                        }
                        )
                    }
                    setTemplateData((prevTemplateData: any) => ({
                        ...prevTemplateData || {},
                        [item?.name || '']: formatResponse || {}
                    }
                    ));
                    setFilteredFields((prevFilteredFields: any) => ({
                        ...prevFilteredFields || {},
                        [item?.name || '']: {
                            ...prevFilteredFields?.[item?.name] || {},
                            [index]: {
                                ...formatResponse || {}
                            }
                        }
                    }))
                    return item;
                }))
            }
            setLoadingFields(false);
            setItemRowChanged(undefined);
            setItemCategoryChanged(undefined);
            setLoadingOptions(undefined);
        }
    };

    useEffect(() => {
        if ((itemRowChanged || itemRowChanged === 0) && itemCategoryChanged) {
            onGettingFieldValues();
        }
    }, [itemRowChanged, itemCategoryChanged, allRowsData]);

    useEffect(() => {
        if (searchField) {
            onGettingFieldValues();
        }
    }, [searchField]);

    useEffect(() => {
        if (templateFields) {
            onGettingFieldValues();
        }
    }, [templateFields]);

    useEffect(() => {
        if (selectedCategory && selectedCategory?.length > 0) {
            setLoadingValues(true);
            onGettingAllAssetFields();
            onGettingFieldValues();
            if (!id) {
                setAllRowsData(undefined);
            }
            setFilteredFields(undefined);
            setSearchField(undefined);
            setSearchInDB(undefined);
        } else {
            setFilteredFields(undefined);
            setSearchField(undefined);
            setSearchInDB(undefined);
            setAllRowsData(undefined);
        }
    }, [selectedCategory]);

    const onSubmitPurchaseForm = async (submit?: boolean) => {
        setLoadingSubmit(true)
        if (!validatePurchaseFields(formFieldsData, setFormFieldsErrors)) {
            setLoadingSubmit(false)
            return;
        }
        if (!onValidateItemsStep()) {
            setLoadingSubmit(false)
            return;
        }
        try {
            const formatItems = (allRowsData && allRowsData?.length > 0) && allRowsData?.map((item: any) => {
                delete item.index;
                const newDescriptor = Object.fromEntries(
                    Object.entries(item.descriptor).filter(([key, value]) => (value !== 'Any'))
                );
                return {
                    ...item,
                    descriptor: newDescriptor,
                    price: parseFloat(item.price || 0),
                    currency: activeMarket?.currency?.descriptor?.name || '',
                };
            });
            const purchaseDataForm = {
                purchaseInfo: formFieldsData || {},
                expectedItems: formatItems,
                status: formFieldsData?.status,
                categories: (selectedCategory && selectedCategory?.length > 0) && selectedCategory?.map((item: any) => ({ id: item?.value, code: item?.name })),
                marketId: activeMarket?.market?._id,
            };
            let response: any;
            if (id && !isCopy) {
                const dataFormat = {
                    id,
                    data: purchaseDataForm,
                };
                response = await dispatch(tryToEditPurchase(dataFormat)).unwrap();
            } else {
                response = await dispatch(tryToCreatePurchase(purchaseDataForm)).unwrap();
            }
            if (submit) {
                await dispatch(tryToSubmitPurchase(response?._id || '')).unwrap();
            }
            document.body.style.overflow = 'auto';
            navigator('/purchase-orders');
            setShowConfirmModal(false)
        } catch (err: any) {
            setErrorStep(err?.response || err || 'Something went wrong!');
        }
        setLoadingSubmit(false)
    };

    const onClickSubmitConfirm = () => {
        if (showConfirmModal) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
        setShowConfirmModal(!showConfirmModal)
    }

    const onClickImportItems = (category: any) => {
        document.body.style.overflow = 'hidden';
        setShowImportItemsModal({
            category: category,
            show: true
        })
    }

    const onCloseImportItems = () => {
        document.body.style.overflow = 'auto';
        setShowImportItemsModal(undefined)
    }

    const onSuccessfullyUploadItems = (data: any, category: any) => {
        setAllRowsData((prevData: any) => ([
            ...prevData || [],
            ...data || [],
        ]))
    }

    const totalPrice = allRowsData?.reduce((accumulator: number, item: any) => accumulator + (Number(item.price || 0) * Number(item?.qty || 0)), 0);
    const totalQuantity = allRowsData?.reduce((accumulator: number, item: any) => accumulator + Number(item.qty || 0), 0);
    return (
        <div>
            <div className='mb-5'>
                <p className='page-title'> Purchases &gt; {id ? 'Edit' : 'Create'} Order</p>
            </div>
            <div className='flex flex-row mt-5 min-w-[100%]'>
                {/* <PurchaseFormStepper activeStep={activeStep} /> */}
            </div>
            {errorStep && (
                <div className='mt-4 px-4'>
                    <Error text={errorStep} />
                </div>
            )}
            <div className='p-4 pt-2 min-w-[100%]'>
                <CategoryStep
                    onClickCategory={onClickCategory}
                    selectedCategory={selectedCategory}
                    categories={brokerCategories}
                />
                <FieldsStep
                    fieldsData={fieldsData}
                    formData={formFieldsData}
                    formErrors={formFieldsErrors}
                    onChangeDateInput={onChangeDateInput}
                    onChangeInput={onChangeInput}
                    onChangeSelectedValue={onChangeSelectedValue}
                />
                <div className='bg-white mb-7'>
                    <ItemsStep
                        selectedCategory={selectedCategory}
                        allRowsItems={allRowsItems}
                        itemsLoading={itemsLoading}
                        filterErrors={itemsErrors}
                        allRowsData={allRowsData}
                        selectedMarket={activeMarket}
                        templateFields={templateData}
                        inventoryFields={inventoryFields}
                        filteredFields={filteredFields}
                        loadingOptions={loadingOptions}
                        loadingValues={loadingValues}
                        loadingFields={loadingFields}
                        itemsGeneralError={itemsGeneralError}
                        searchInDB={searchInDB}
                        categoryFieldsWithoutExtended={templateFields}
                        onCloneRow={onCloneRow}
                        onClickAddRow={onClickAddItems}
                        onDeleteItemRow={onDeleteItemRow}
                        onClickSearchInDb={onClickSearchInDb}
                        onClickImportItems={onClickImportItems}
                        onChangeInputValue={onChangeInputValueTable}
                        onMenuCloseAfterSearch={onMenuCloseAfterSearch}
                        onChangeSelectedValue={onChangeSelectedValueTable}
                        onChangeInventoryInput={onChangeFilterInventoryInput}
                        onChangeSearchAssetFields={onChangeSearchAssetFields}
                        onChangeSelectedInventoryValue={onChangeSelectedInventoryValue}
                    />
                    {(selectedCategory && selectedCategory?.length > 0) &&
                        <div className='flex flex-col items-center py-4'>
                            <p className='mb-2 text-center'>Total Quantity: {totalQuantity || 0} | Total Price: {CURRENCY_SYMBOLS?.[`${(activeMarket?.currency?.descriptor?.name || '').toLowerCase()}`]}{Number(totalPrice || 0).toFixed(2)}</p>
                            <div className='flex flex-row justify-center my-2'>
                                <Button
                                    label='Submit purchase order'
                                    className='btn-primary'
                                    onClickButton={onClickSubmitConfirm}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
            {showImportItemsModal?.show &&
                <ImportPurchaseItemsModal
                    openImportModal={showImportItemsModal?.show}
                    category={showImportItemsModal?.category}
                    templateFields={templateData?.[showImportItemsModal?.category?.name] && Object.keys(templateData?.[showImportItemsModal?.category?.name])}
                    inventoryFields={inventoryFields?.[showImportItemsModal?.category?.name]?.map((item: any) => item?.name) || []}
                    handleCloseModal={onCloseImportItems}
                    onSuccessfullyUploadItems={onSuccessfullyUploadItems}
                />
            }
            {showConfirmModal &&
                <ConfirmPurchaseCreate
                    openModal={showConfirmModal}
                    items={allRowsData}
                    market={activeMarket}
                    categories={selectedCategory}
                    edit={(id && !isCopy) || false}
                    detailsInfo={formFieldsData}
                    generalError={errorStep}
                    submitLoading={loadingSubmit}
                    accessControl={accessControl}
                    pageActions={pageAccess?.actions}
                    handleCloseModal={onClickSubmitConfirm}
                    onApproveAction={onSubmitPurchaseForm}
                />
            }
        </div>
    );
};

export default PurchaseForm;