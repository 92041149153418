import React, { useCallback } from 'react';
import { useGetCurrentUserQuery } from '../../../store/user/userApi';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { tryToCreateSessionConversations } from '../../../store/brokers/ai-agents/aiAgentsSlice';

const AiAgentsContainer = () => {
	const {data} = useGetCurrentUserQuery();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const handleGoToSeller = useCallback(async () => {
		const response = await dispatch(tryToCreateSessionConversations({sessionId: data?.sessionId || '', type: 'seller'})).unwrap()
		console.log({ response })
		navigate(`/ai-agents/chat/sell/${data?.sessionId}`)
	}, [navigate, data]);
	const handleGoToBuyer = useCallback(async () => {
		const response = await dispatch(tryToCreateSessionConversations({sessionId: data?.sessionId || '', type: 'buyer'})).unwrap()
		console.log({ response })
		navigate(`/ai-agents/chat/buy/${data?.sessionId}`)
	}, [navigate, data]);
	return (
		<>
			<h2 className="page-title mb-10">AI Agents</h2>
			<div className='w-full border-[#ebedef] border rounded-2xl flex flex-wrap bg-white pt-5'>
				<div className='w-full md:w-4/12 p-4 pb-0 pr-12'>
					<p className='text-sm font-normal text-center mb-2'>Hi <b>{data?.name || data?.username}</b>, I am Lois, your personal AI assistant and I will help you do what you need.</p>
					<img alt='agent' className='mx-auto' src='/assets/ai-agents/agent.svg' />
				</div>
				<div className='w-full md:w-8/12 flex flex-wrap py-4'>
					<div className='w-full md:w-6/12 flex flex-wrap px-2 rounded-lg'>
						<button className='bg-[#F8F9FC] hover:bg-gray-100 p-4 w-full flex flex-col justify-center items-center rounded' onClick={handleGoToSeller} type='button'>
							<img alt='upload' src='/assets/ai-agents/upload-inventory.svg' />
							<h4 className='text-xl font-bold'>Upload Inventory</h4>
							<p className='text-sm text-[#484A4B]'>Items that you want to find prices and sell</p>
						</button>
					</div>
					<div className='w-full md:w-6/12 flex flex-wrap px-2 rounded-lg'>
						<button className='bg-[#F8F9FC] hover:bg-gray-100 p-4 w-full flex flex-col justify-center items-center rounded' onClick={handleGoToBuyer} type='button'>
							<img alt='upload' src='/assets/ai-agents/buy-inventory.svg' />
							<h4 className='text-xl font-bold'>Update Pricing</h4>
							<p className='text-sm text-[#484A4B]'>Items that you want to find prices and buy</p>
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default AiAgentsContainer;