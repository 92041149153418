import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IAssetFields } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { IToast } from '../../../../interfaces/components/IToast';
import {
    tryToDisableAssetTemplateField,
    tryToFetchAssetTemplateFilters,
} from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import FieldsModal from '../modals/FieldsModal';
import AssetFiltersModal from '../modals/AssetFiltersModal';
import ReplaceFieldsModal from '../modals/ReplaceFieldsModal';
import AssetTemplateFieldsTable from '../tables/AssetTemplateFieldsTable';
import Toast from '../../../../shared/toast';
import Button from '../../../../shared/button';
import DeleteModal from '../../../../shared/delete-modal';
import EmptyContainer from '../../../../shared/empty-container';
import FieldPricingModal from '../modals/FieldPricingModal';


const CatalogFields = () => {
    const navigate = useNavigate()
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<IAssetFields[]>([]);
    const [showToast, setShowToast] = useState<IToast | null>()
    const [assetFilters, setAssetFilters] = useState<Array<any>>()
    const [fieldItemId, setFieldItemId] = useState<string | null>()
    const [showFieldModal, setShowFieldModal] = useState<boolean>(false)
    const [showReplaceFieldModal, setShowReplaceFieldModal] = useState<boolean>(false)
    const [showDeleteFieldModal, setShowDeleteFieldModal] = useState<boolean>(false)
    const [showAssetFiltersModal, setShowAssetFiltersModal] = useState<boolean>(false)
    const [showPricingFieldModal, setShowPricingFieldModal] = useState<boolean>(false)
    const [pageAccess, setPageAccess] = useState<any>()
    const state = useAppSelector((state) => state.assetsTemplates);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const stateFilters = useAppSelector((state) => state.assetsTemplates?.assetTemplateFilters);
    const rowsLoading = useAppSelector((state) => state.assetsTemplates?.assetTemplateFieldsAreLoading);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Asset Catalogs')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Asset Catalog Details')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.assetTemplateDetails) {
            const rows =
                state.assetTemplateDetails && state.assetTemplateDetails.fields && Array.isArray(state.assetTemplateDetails.fields)
                    ? state.assetTemplateDetails.fields?.filter((r) => r?.enabled).map((r) => ({ ...r, id: r.id }))
                    : [];
            setRows(rows);
        }
    }, [state.assetTemplateDetails]);

    useEffect(() => {
        if (stateFilters) {
            setAssetFilters(stateFilters)
        }
    }, [stateFilters])

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchAssetTemplateFilters({ id }))
        }
    }, []);

    const onCloseFieldModal = () => {
        setFieldItemId(null)
        setShowFieldModal(false)
        setShowReplaceFieldModal(false)
    }

    const onAddNewAssetTemplateField = (id?: string) => {
        if (id) {
            setFieldItemId(id)
        }
        setShowFieldModal(true)
    }

    const onEditReplaceField = (id: string) => {
        setFieldItemId(id)
        setShowReplaceFieldModal(true)
    }

    const onDisableAssetField = async () => {
        try {
            if (fieldItemId) {
                const data = {
                    assetId: id,
                    fieldId: fieldItemId
                }
                await dispatch(tryToDisableAssetTemplateField(data)).unwrap();
                setShowToast({ type: 'error', message: 'Field deleted successfully' });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setFieldItemId(null)
        setShowDeleteFieldModal(false)
    }

    const onDeleteAssetField = (id: string) => {
        setFieldItemId(id)
        setShowDeleteFieldModal(true)
    }

    const onSaveFieldChanges = async (type: string, message: string) => {
        setShowToast({ type, message });
    }

    const onCloseDeleteFieldModal = () => {
        setFieldItemId(null)
        setShowDeleteFieldModal(false)
    }

    const onToggleAssetFilterModal = () => {
        setShowAssetFiltersModal(!showAssetFiltersModal)
    }

    const onTogglePricingFieldModal = () => {
        setShowPricingFieldModal(!showPricingFieldModal)
    }

    const onEditFieldDetails = (fieldId: string) => {
        navigate(`/asset-catalogs/${id}/field/${fieldId}`)
    }

    return (
        <div>
            <div className='flex flex-row justify-end'>
                <Button
                    label={'Setup Pricing Field'}
                    className={`!shadow-none ${(!accessControl || pageAccess?.actions?.['Setup Pricing Field'] && (rows && rows?.length > 0)) ? 'btn-main' : 'btn-main-disable'} mr-2`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Setup Pricing Field'] && (rows && rows?.length > 0)) && onTogglePricingFieldModal()}
                    dataQa={'setup-pricing-field'}
                />
                <Button
                    label={'Setup Filters'}
                    className={`!shadow-none ${(!accessControl || pageAccess?.actions?.['Setup Filters']) ? 'btn-main' : 'btn-main-disable'} mr-2`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Setup Filters']) && onToggleAssetFilterModal()}
                    dataQa={'setup-filters'}
                />
                <Button
                    label={'Add New Field'}
                    className={`${(!accessControl || pageAccess?.actions?.['Add New Field']) ? 'btn-main' : 'btn-main-disable'}`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Field']) && onAddNewAssetTemplateField()}
                    dataQa={'add-new-field'}
                />
            </div>
            <div className='my-5 relative'>
                {(rows?.length > 0 || rowsLoading) ?
                    <AssetTemplateFieldsTable
                        rows={rows}
                        assetId={id || ''}
                        type={'fields'}
                        rowsLoading={rowsLoading}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        onDeleteAssetField={onDeleteAssetField}
                        onEditAssetField={onAddNewAssetTemplateField}
                        onEditReplaceField={onEditReplaceField}
                        onEditFieldDetails={onEditFieldDetails}
                    />
                    : <EmptyContainer text={'No field added yet'} showImage={true} />
                }
            </div>
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            <FieldsModal
                openFieldsModal={showFieldModal}
                handleCloseFieldsModal={onCloseFieldModal}
                onSaveChanges={onSaveFieldChanges}
                assetTemplateId={id || ''}
                fieldItemId={fieldItemId}
            />
            {showReplaceFieldModal &&
                <ReplaceFieldsModal
                    openFieldsModal={showReplaceFieldModal}
                    handleCloseFieldsModal={onCloseFieldModal}
                    assetTemplateId={id || ''}
                    fieldItemId={fieldItemId || ''}
                    onSaveChanges={onSaveFieldChanges}
                />
            }
            <DeleteModal
                openDeleteModal={showDeleteFieldModal}
                handleCloseDeleteModal={onCloseDeleteFieldModal}
                onApproveDeletion={onDisableAssetField}
            />
            <AssetFiltersModal
                openFilterModal={showAssetFiltersModal}
                handleCloseModal={onToggleAssetFilterModal}
                items={assetFilters || []}
                fields={rows || []}
                assetTemplateId={id || ''}
            />
            {showPricingFieldModal &&
                <FieldPricingModal
                    openModal={showPricingFieldModal}
                    assetTemplateId={id || ''}
                    handleCloseModal={onTogglePricingFieldModal}
                />
            }
        </div >
    )
}

export default CatalogFields;