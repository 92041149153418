import React, { useEffect, useState } from 'react';
import { IItem } from '../../../../interfaces/items/IItem';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { useGetAllMarketsMutation } from '../../../../store/user/userDomApi';
import { IMarket } from '../../../../interfaces/markets/IMarket';
import Table from '../../../../shared/table';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';

const tableColumns = ['Item ID', 'Item Code', 'Category', 'Name', 'Sales Grade', 'Price', 'Order ID']


interface IReadyToShipItemsTable {
    rows: IItem[];
    paginationData?: IPage;
    rowsLoading: boolean;
    selectedRows: string[] | undefined;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onClickSelectRow: (id?: string) => void;
    sectors: IAssetTemplate[];
}
const ReadyToShipItemsTable = ({
    rows,
    paginationData,
    rowsLoading,
    selectedRows,
    onClickSelectRow,
    setPaginationState,
    sectors,
}: IReadyToShipItemsTable) => {
    const [markets, setMarkets] = useState<IAllMarkets[]>();
    const [getAllMarkets] = useGetAllMarketsMutation();
    const getMarketData = async () => {
        const marketResponse = await getAllMarkets(null).unwrap();
        const formatMarkets =
            marketResponse &&
            marketResponse?.length > 0 &&
            marketResponse?.map((market: IMarket) => ({
                ...market,
                value: market?._id,
                displayName: market?.name,
            }));
        setMarkets(formatMarkets || []);
    };
    useEffect(() => {
        getMarketData();
    }, []);

    const getItemIcon = (item: any) => {
        const res = sectors.find((category) => category.name === item?.category);
        return res?.disabledIcon;
    };

    return <Table
    selectRows={true}
    areAllRowsSelected={(selectedRows && rows && rows.every(r => selectedRows.includes(r.item?._id||'')))}
    columns={tableColumns}
    containerStyle={'mt-0'}
    paginationData={paginationData}
    onClickSelectAll={() => onClickSelectRow()}
    setPaginationState={setPaginationState}
    selectStyles='!accent-[#202020] !focus:accent-[#202020]'
>
    {rowsLoading ?
        <>
            {[...Array(4)].map((x, i) => {
                return (
                    <tr key={i}>
                        {[...Array(tableColumns?.length || 1)].map((x, j) => {
                            return (
                                <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                            )
                        })}
                    </tr>
                )
            })}
        </>
        : <>
            {rows?.length > 0 && rows?.map((row: IItem, idx: number) => {
                const findIfRowIsSelected = selectedRows && selectedRows?.length > 0 && selectedRows?.includes(row?.item?._id ||'')
                const findMarket = markets && markets?.length > 0 && markets?.find((market: any) => market?.name === row?.item?.exchangeDefinition?.warehouse?.address?.country)
                return (
                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                        <td className='px-6 py-4'>
                            <div className='flex items-center'>
                                <input
                                    onChange={() => onClickSelectRow(row?.item?._id)}
                                    checked={!!findIfRowIsSelected}
                                    data-qa={'checkbox-type'}
                                    id='checkbox-all'
                                    type='checkbox'
                                    className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 accent-[#202020] rounded focus:accent-[#202020] focus:ring-2' />
                                <label htmlFor='checkbox-all' className='sr-only'>checkbox</label>
                            </div>
                        </td>
                        <td className='px-6 py-4'>{row?.item?._id}</td>
                        <td className='px-6 py-4'>{row?.item?.itemCode || 'No IMEI'}</td>
                        <td className='px-6 py-6 flex gap-1 items-center'>
                            <img className='w-6 h-6' src={getItemIcon(row.item)} alt={row?.item?.category||''} title={row?.item?.category||''} />
                        </td>
                        <td className='px-6 py-4'>{row?.item?.itemName}</td>
                        <td className='px-6 py-4'>{row?.item?.inventory?.sales_grade ? `Grade ${row.item.inventory.sales_grade}` : 'Not graded'}</td>
                        <td className='px-6 py-4'>
                            <div className='flex flex-row items-center '>
                                {findMarket && CURRENCY_SYMBOLS?.[`${(findMarket?.currency?.name || '')}`]}
                                {Number(row?.item?.salePrice || 0).toFixed(2)}
                            </div>
                        </td>
                        <td className='px-6 py-4'>{row?.item?.orderId}</td>
                    </tr>
                )
            })}
        </>
    }
</Table>;
};

export default ReadyToShipItemsTable;
