import { decodeJWT } from '../validateJWT';

const intercomIntegration = async (userHash: any, userId: string) => {
    const { name, email } = decodeJWT(localStorage.getItem('accessToken')) || {};

    (window as any).Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'qwdo5cmp',
        name: name,
        user_id: userId,
        email: email,
        created_at: new Date(),
        user_hash: userHash,
    });
};

export default intercomIntegration;