import React, { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToChangeAssetsStatus } from '../../../../store/brokers/admin/assets/assetsSlice';
import ChangeMultipleStatusErrorStep from '../multiple-status-change/ChangeMultipleStatusErrorStep';
import AssetExtendedModal from '../../assets-extended/modals/AssetExtendedModal';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface IChangeBatchItemsStatusModal {
    openModal: boolean;
    assetTemplateId: string;
    assetIds: Array<string>;
    handleCloseModal: () => void;
    onSaveSuccessfully: (message: string) => void;
}

const ChangeBatchItemsStatusModal = ({
    openModal,
    assetTemplateId,
    assetIds,
    handleCloseModal,
    onSaveSuccessfully
}: IChangeBatchItemsStatusModal) => {
    const dispatch = useAppDispatch();
    const [saveError, setSaveError] = useState<string>();
    const [errorResponse, setErrorResponse] = useState<any>();
    const [showAssetExtended, setShowAssetExtended] = useState<{ show: boolean, asset: string }>()
    const [assetToDelete, setAssetToDelete] = useState<string>()

    const onCloseModal = () => {
        handleCloseModal();
        setErrorResponse(undefined)
        setAssetToDelete(undefined)
        setShowAssetExtended(undefined)
        setSaveError(undefined)
    }

    const onSubmit = async (status: any) => {
        try {
            const formData = {
                assetTemplateId: assetTemplateId,
                data: {
                    assets: assetIds,
                    status: status
                }
            }
            await dispatch(tryToChangeAssetsStatus(formData)).unwrap()
            onSaveSuccessfully('Assets statuses changed successfully')
            onCloseModal()
        } catch (err: any) {
            setSaveError(`${err?.response?.data?.message}`)
            setErrorResponse(err?.response?.data?.models)
        }
    }

    const onClickAddAssetsExtendedModal = (name: string) => {
        setShowAssetExtended({
            show: true,
            asset: name
        })
        setAssetToDelete(name)
    }

    const onSaveSuccessfullyExteneded = (type: string, message: string) => {
        const filterErrorResponse = (errorResponse && errorResponse?.length > 0) && errorResponse?.filter((item: any) => item !== assetToDelete)
        setErrorResponse(filterErrorResponse)
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            contentContainerStyle={errorResponse ? 'min-w-[700px]' : 'min-w-[650px]'}>
            <div className={`p-4 ${errorResponse ? 'min-w-[650px] min-h-[500px]' : 'min-w-[600px]'} `}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Assets Statuses</p>
                </div>
                {saveError && <Error text={saveError} />}
                <div className='my-5'>
                    <p>You can change selected Assets status by clicking the button below.</p>
                </div>
                {errorResponse &&
                    <ChangeMultipleStatusErrorStep
                        errorResponse={errorResponse}
                        onClickAddAssetsExtendedModal={onClickAddAssetsExtendedModal}
                    />
                }
                <div className='flex flex-row justify-end'>
                    <Button
                        className='btn-main'
                        label={'Enable Asset'}
                        onClickButton={() => onSubmit('enabled')}
                    />
                    <Button
                        className='btn-error !rounded ml-3'
                        label={'Disable Asset'}
                        onClickButton={() => onSubmit('disabled')}
                    />
                </div>

                {showAssetExtended?.show &&
                    <AssetExtendedModal
                        openCreateAssetModal={showAssetExtended?.show}
                        name={showAssetExtended?.asset}
                        handleCloseAssetModal={() => setShowAssetExtended(undefined)}
                        onSaveChanges={onSaveSuccessfullyExteneded}
                    />
                }
            </div>
        </Modal>
    )
}

export default ChangeBatchItemsStatusModal;