import React, { useCallback, useState } from 'react';
import { IMatches } from '../../../interfaces/ai-agents/IItem';
import Button from '../../../shared/button';
import { useAppDispatch } from '../../../app/hooks';
import { tryToAddConversationAiAgent, tryToAddNextConversationAiAgent } from '../../../store/brokers/ai-agents/aiAgentsSlice';
import { useParams } from 'react-router-dom';

const MatchesDetailsItem = ({
	message,
	fullMatches,
	partialMatches,
	isLoading,
	type,
	selectItemsToBuy
}: {
	message: string,
	fullMatches?: IMatches[],
	partialMatches?: IMatches[],
	isLoading: boolean,
	type?: 'seller' | 'buyer',
	selectItemsToBuy?: boolean
}) => {
	const dispatch = useAppDispatch();
	const [matches, setMatches] = useState<IMatches[]>()
	const { id } = useParams();
	const handleClickCheckbox = (match: IMatches, index: number, type: 'full' | 'partial') => {
		setMatches(matches ? (matches.find(match => match.index === index && match.type === type) ? matches.filter(match => match.index !== index || match.type !== type) : [...matches, { ...match, index, type }]) : [{ ...match, index, type }]);
	}

	const handleContinue = useCallback(async () => {
		const addChat = await dispatch(tryToAddConversationAiAgent({ answer: JSON.stringify(matches), customMessage: [...matches || []]?.map(match => `${match.asset.make} ${match.asset.model} ${match.asset.storage}`).join(', '), chatId: id || '', type, selectItemsToBuy: true })).unwrap();
		if (addChat.success && !addChat.finalStep) {
			await dispatch(tryToAddNextConversationAiAgent({ chatId: id || '', type })).unwrap();
		}
	}, [id, matches]);

	return (
		<div className='w-full'>
			<p>{message}</p>
			<div className='w-full px-2 mt-4'>
				<h4>Fully Match items</h4>
				<table className='w-full rounded-lg'>
					<thead>
						{fullMatches?.map((match, index: number) =>
							<tr key={index} >
								<td className='bg-[#F8F9FC] p-2 border-b border-dashed text-sm w-12'>{index + 1}</td>
								<td className='bg-[#F8F9FC] p-2 border-b border-dashed text-sm w-96'>{match.asset.make} {match.asset.model} {match.asset.storage},  {match.asset.condition}, {match.asset.network}</td>
								<td className='bg-[#F8F9FC] p-2 border-b border-dashed text-sm'>{match.inventory.sales_grade}</td>
								<td className='bg-[#F8F9FC] p-2 border-b border-dashed text-sm'>{match.quantity}</td>
								<td className='bg-[#F8F9FC] p-2 border-b border-dashed text-sm text-[#8A8A8E]'>
									{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(match.price)}
								</td>
								<td className='bg-[#F8F9FC] p-2 border-b border-dashed text-sm'>
									<input type='checkbox' onChange={() => handleClickCheckbox(match, index, 'full')} />
								</td>
							</tr>
						)}
						<tr>
							<td colSpan={6}>
								<h4 className='mb-2 mt-1'>Partial Match items</h4>
							</td>
						</tr>
					</thead>
					<tbody>
						{partialMatches?.map((match, index: number) =>
							<tr key={index} >
								<td className='bg-[#F8F9FC] p-2 border-b border-dashed text-sm w-12'>{index + 1}</td>
								<td className='bg-[#F8F9FC] p-2 border-b border-dashed text-sm w-96'>{match.asset.make} {match.asset.model} {match.asset.storage},  {match.asset.condition}, {match.asset.network}</td>
								<td className='bg-[#F8F9FC] p-2 border-b border-dashed text-sm'>{match.inventory.sales_grade}</td>
								<td className='bg-[#F8F9FC] p-2 border-b border-dashed text-sm'>{match.quantity}</td>
								<td className='bg-[#F8F9FC] p-2 border-b border-dashed text-sm text-[#8A8A8E]'>
									{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(match.price)}
								</td>
								<td className='bg-[#F8F9FC] p-2 border-b border-dashed text-sm'>
									<input type='checkbox' onChange={() => handleClickCheckbox(match, index, 'partial')} />
								</td>
							</tr>
						)}
					</tbody>
					<tfoot>
						<tr>
							<td colSpan={2} className='pt-5'>
								{!selectItemsToBuy && <Button
									disabled={isLoading || Number(matches?.length || 0) < 1}
									loading={isLoading}
									className='text-[#00B67A] text-sm font-normal hover:underline'
									label={`Continue (${matches?.length || 0})`}
									onClickButton={handleContinue}
								/>}
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	)
}

export default MatchesDetailsItem;