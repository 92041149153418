import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToSortAssetTemplateField } from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { ISortAssetField } from '../../../../interfaces/asset-templates/IAssetTemplateSchema';
import { IAssetFields } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import Table from '../../../../shared/table';
import Button from '../../../../shared/button';


interface IAssetTemplateFieldsTable {
    assetId: string;
    rows: IAssetFields[];
    type?: string;
    accessControl?: IAccessControl;
    actions?: IPageActions;
    rowsLoading: boolean;
    onEditAssetField: (id: string) => void;
    onDeleteAssetField: (id: string) => void;
    onEditReplaceField?: (id: string) => void;
    onEditFieldDetails?: (id: string) => void;
}

const AssetTemplateFieldsTable = ({
    assetId,
    rows,
    type,
    accessControl,
    actions,
    rowsLoading,
    onEditAssetField,
    onDeleteAssetField,
    onEditReplaceField,
    onEditFieldDetails
}: IAssetTemplateFieldsTable) => {
    const dispatch = useAppDispatch();
    const [data, setData] = useState(rows);
    const [tableColumns, setTableColumns] = useState<Array<string>>(['Name', 'Datatype', 'Is Required', 'Values', 'Actions'])
    const [orderChanged, setOrderChanged] = useState<boolean>(false)
    const dragItem = React.useRef<any>(null)
    const dragOverItem = React.useRef<any>(null)

    useEffect(() => {
        setData(rows)
    }, [rows])

    useEffect(() => {
        if (type === 'inventory') {
            setTableColumns(['Name', 'Datatype', 'Is Required', 'Values', 'Show In Inventory', 'Actions'])
        }
        else if (type === 'extended') {
            setTableColumns(['Name', 'Datatype', 'Is Required', 'Values', 'Actions'])
        }
        else if (type === 'rental') {
            setTableColumns(['Name', 'Datatype', 'Is Required', 'Values', 'Actions'])
        }
        else {
            setTableColumns(['Name', 'Datatype', 'Is Required', 'Values', 'Show In Inventory', 'Hidden In App', 'Field Other Details', 'Replace Field', 'Actions'])
        }
    }, [type, assetId, rowsLoading, rows])

    const handleSort = (e: React.DragEvent<HTMLTableRowElement>) => {
        const allRows = [...data]

        const draggedItemContent = allRows.splice(dragItem.current, 1)[0]
        allRows.splice(dragOverItem.current, 0, draggedItemContent)

        dragItem.current = null
        dragOverItem.current = null
        e.currentTarget.style.opacity = '1'
        setOrderChanged(true)
        setData(allRows)
    }

    const onResortTemplateFields = async () => {
        const formatData = (data && data?.length > 0) && data?.map((row, index) => {
            return {
                id: row?.id || '',
                order: index + 1
            }
        })
        if (formatData) {
            const dataParam: ISortAssetField = {
                assetId,
                sortedValues: formatData
            }
            await dispatch(tryToSortAssetTemplateField(dataParam)).unwrap();
            setOrderChanged(false)
        }
    }

    useEffect(() => {
        if (orderChanged) {
            onResortTemplateFields()
        }
    }, [orderChanged])

    const handleOnDragOver = (e: React.DragEvent<HTMLTableRowElement>) => {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.style.backgroundColor = '#e7e7e7';
        e.currentTarget.style.transition = '0s'
    }

    const handleOnDrop = (e: React.DragEvent<HTMLTableRowElement>) => {
        e.currentTarget.style.backgroundColor = '';
        e.currentTarget.style.transition = '3s'
    }

    const handleOnDragEnter = (e: React.DragEvent<HTMLTableRowElement>, idx: number) => {
        dragOverItem.current = idx;
        e.currentTarget.style.backgroundColor = '#e7e7e7';
        e.currentTarget.style.transition = '0s'
    }

    const handleOnDragStart = (e: React.DragEvent<HTMLTableRowElement>, idx: number) => {
        dragItem.current = idx;
        e.currentTarget.style.transition = '0s'
        e.currentTarget.style.opacity = '0.4'
    }

    return (
        <Table
            columns={tableColumns}
            hidePagination={true}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 5)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {data?.length > 0 && data?.map((row: IAssetFields, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'
                                draggable
                                onDragOver={(e) => handleOnDragOver(e)}
                                onDragLeave={(e) => (e.currentTarget.style.backgroundColor = '')}
                                onDrop={(e) => handleOnDrop(e)}
                                onDragStart={(e) => handleOnDragStart(e, idx)}
                                onDragEnter={(e) => handleOnDragEnter(e, idx)}
                                onDragEnd={(e) => handleSort(e)}
                            >
                                <td data-qa={'edona'} className='px-6 py-4'>{row?.name}</td>
                                <td className='px-6 py-4'>{row?.dataType}</td>
                                <td className='px-6 py-4'>{row?.required ? 'Yes' : 'No'}</td>
                                <td className='px-6 py-4'>
                                    {row?.values && row?.values?.map((item, index) => `${index ? ', ' : ''}${item}`)}
                                </td>
                                {(type !== 'rental' && type !== 'extended') && <td className='px-6 py-4'>{row?.showInInventory ? 'Yes' : 'No'}</td>}
                                {type === 'fields' &&
                                    <>
                                        <td className='px-6 py-4'>{row?.hiddenInApp ? 'Yes' : 'No'}</td>
                                        <td className='px-6 py-4'>
                                            <Button
                                                label={'Field Details'}
                                                className={`${(row?.id && (!accessControl || actions?.['Field Details'])) ? 'btn-main' : 'btn-main-disable'} !shadow-none min-w-[130px] my-3`}
                                                onClickButton={() => (row?.id && (!accessControl || actions?.['Field Details'])) && onEditFieldDetails?.(row?.id)}
                                                dataQa={`field-details-${idx}`}
                                            />
                                        </td>
                                        <td className='px-6 py-4'>
                                            <Button
                                                label={'Set Details'}
                                                className={`${(row?.id && (!accessControl || actions?.['See Field Details'])) ? 'btn-main' : 'btn-main-disable'} !shadow-none min-w-[130px] my-3`}
                                                onClickButton={() => (row?.id && (!accessControl || actions?.['See Field Details'])) && onEditReplaceField?.(row?.id)}
                                                dataQa={`set-details-${idx}`}
                                            />
                                        </td>
                                    </>
                                }
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-center'>
                                        <div data-qa={'edit-asset-field'} onClick={() => (row?.id && (!accessControl || actions?.['Edit Field'])) && onEditAssetField(row?.id)}>
                                            <img title={'Edit Asset Field'} data-qa={'edit-btn'} alt='edit item' src={'/assets/table-actions/pen.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Edit Field']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                        </div>
                                        <div data-qa={'delete-asset-field'} onClick={() => (row?.id && (!accessControl || actions?.['Delete Field'])) && onDeleteAssetField(row?.id)}>
                                            <img title={'Delete Asset Field'} data-qa={'delete-btn'} alt='delete item' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Delete Field']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-1`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default AssetTemplateFieldsTable;